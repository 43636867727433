<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Wallet</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li><a href="/dashboard"><i class="fa fa-home"></i></a></li>
            </ol>
        </div>
    </header>
    <div class="panel">
        <h2 class="create-advert-intro">Account Balance</h2>
        <h4 class="text-bold ">Withdraw funds, view deposit history, Withdraw history.</h4>
        <div class="row  mt-lg">
            <div class=" col-sm-12 col-md-6 col-lg-6 mt-lg">
                <div class="panel-body">
                    <div>
                        <p>Minimum withdrawal is {{ currencySymbol(currency) }} {{ formatAmount(min_withdrawal) }}</p>
                    </div>
                    <div class="viral-card viral-blue">
                        <div><strong>Account Balance:&nbsp;{{ currencySymbol(currency) }}{{ formatAmount(wallet) }}</strong></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 mt-lg">
                <div class="panel-body"><span class="pd-sm pt-sm  pr-sm text-bold">BANK DETAILS</span>
                    <div class="viral-blue viral-card pb-sm pt-sm pl-sm pr-sm">
                        <p class="pl-md pr-md pt-xs pb-xs mb-sm  label label-lg label-primary">Account Name</p>
                        <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px;"> {{ name }} </p><br>
                        <hr class="mt-xs mb-xs">
                        <p class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">Account Number</p>
                        <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px;"> {{ account_no }} </p><br>
                        <hr class="mt-xs mb-xs">
                        <p class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">Bank</p>
                        <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px;"> {{ bank }} </p><br>
                        <hr class="mt-xs mb-xs">
                        <p class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">Account Type</p>
                        <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px;"> {{ account_type }} </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-lg">
                <div class="panel-body"><span class="pd-sm pt-sm  pr-sm text-bold">WITHDRAWAL REQUEST</span>
                    <div class="viral-card viral-yellow">
                        <div class=" mt-xs">
                            <div class="row">
                                <div class="form-group col-md-5 col-lg-5">
                                    <div class="input-group  viral-card viral-dark" style="padding: 5px;">
                                        <span class="input-group-addon">{{currency}}</span> 
                                        <label for="#withdrawAmount" class="sr-only">Withdrawal Amount</label> 
                                        <input v-model="amount" type="text" @keyup="calculateToReceive" id="withdrawAmount" placeholder="Enter Amount" required="required" class="form-control">
                                    </div>
                                    <p>
                                        <span><i class="fa fa-warning"></i></span>
                                        &nbsp;Note: Service Charge + Taxes + ITED = {{ withdrawal_fee }}%
                                    </p> 
                                    <p v-if="toReceive > 0">You receive {{currency}}{{ toReceive }}</p>
                                    <input value="" id="m" style="display: none;">
                                </div>
                                <!---->
                                <div class="text-center form-group col-md-5 col-lg-5">
                                    <button v-if="loading == false" @click="withdraw" class="btn btn-primary viral-btn btn-lg button">
                                        Withdraw
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 ">
            <div class="row">
                <div hidden class="d-none col-sm-12 col-md-12 col-lg-12 mt-sm mb-sm">
                    <div class="panel panel-primary">
                        <header class="panel-heading">
                            <h2 class="panel-title text-bold">Platform Deposits History</h2>
                        </header>
                        <div class="panel-body table-responsive">
                            <table id="grantDepositsOLD" class="table mb-none">
                                <thead>
                                    <tr>
                                        <th>Amount</th>
                                        <th>From</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><span class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">₦3.00</span></td>
                                        <td><span class="pl-md pr-md pt-xs pb-xs label label-lg label-dark">task</span></td>
                                        <td><span class="pl-md pr-md pt-xs pb-xs  label label-lg label-default"></span></td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul role="navigation" class="pagination">
                                <li aria-disabled="true" aria-label="« Previous" class="page-item disabled"><span aria-hidden="true" class="page-link">‹</span></li>
                                <li aria-current="page" class="page-item active"><span class="page-link">1</span></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=2" class="page-link">2</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=3" class="page-link">3</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=4" class="page-link">4</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=5" class="page-link">5</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=6" class="page-link">6</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=7" class="page-link">7</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=8" class="page-link">8</a></li>
                                <li aria-disabled="true" class="page-item disabled"><span class="page-link">...</span></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=22" class="page-link">22</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=23" class="page-link">23</a></li>
                                <li class="page-item"><a href="https://viraltrend.org/promoters/wallet?page=2" rel="next" aria-label="Next »" class="page-link">›</a></li>
                            </ul>
                            <p>
                                Total: 38
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mt-sm mb-sm">
                    <div class="panel panel-primary">
                        <header class="panel-heading">
                            <h2 class="panel-title">Withdrawal History</h2>
                        </header>
                        <div class="panel-body table-responsive">
                            <table id="grantHistoryOLD" class="table mb-none">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Amount</th>
                                        <th>Date</th>
                                        <th>To get</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="withdraw in withdrawals">
                                        <td>
                                            <span class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">
                                                {{ withdraw.id }}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">
                                                {{ currencySymbol(currency) }}{{ formatAmount(withdraw.amount) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="pl-md pr-md pt-xs pb-xs  label label-lg label-default">
                                                {{ formatDateTimeNiceShort(withdraw.created_at) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="withdraw.amount_to_receive" class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">
                                                {{ currencySymbol(currency) }}{{ formatAmount(withdraw.amount_to_receive) }}
                                            </span>
                                            <span v-else class="pl-md pr-md pt-xs pb-xs  label label-lg label-primary">
                                                {{ currencySymbol(currency) }}{{ formatAmount(calculateAmountToReceive(withdraw.amount)) }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="withdraw.status == 'deactive'" class="pl-md pr-md pt-xs pb-xs label label-lg label-danger">
                                                Rejected
                                            </span>
                                            <span v-else-if="withdraw.status == 'complete' || withdraw.status == 'completed'" class="pl-md pr-md pt-xs pb-xs label label-lg label-success">
                                                Paid
                                            </span>
                                            <span v-else-if="withdraw.status == 'pending'" class="pl-md pr-md pt-xs pb-xs label label-lg label-warning">
                                                In Progress
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                Total: {{ currency }}{{ formatAmount(total_withdrawal_sum) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: 'Wallet',
    components: {},
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            name: '',
            account_no: '',
            account_type: '',
            bank: '',
            bank_account: '',
            wallet: '',
            currency: '',
            withdrawal_fee: '',
            withdrawals: '',
            total_withdrawal_sum: '',
            amount: '',
            min_withdrawal: '',
            max_withdrawal: '',
            loading: true,
            toReceive: 0,
            page: 0,
            per_page: 25
        }
    },
    mounted: function () {
        this.toggleProgress('show')
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.getWallet()
        this.getBankAccount()
        this.getWithdrawals()
        this.toggleProgress('hide')
    },
    methods: {
        async getWallet() {
            this.loading = true
            let apiResponse = await ApiService.GetWallet();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.wallet = apiResponse['data']
                this.currency = apiResponse['currency']
                this.currentUser = apiResponse['user']
            }
            this.loading = false
        },
        async getBankAccount() {
            this.loading = true
            let apiResponse = await ApiService.GetBankAccount();
            // console.log('UserFollowership',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.bank_account = apiResponse['data']
                this.account_no = this.bank_account['account_no']
                this.bank = this.bank_account['bank']
                this.account_type = this.bank_account['account_type']
                this.name = this.bank_account['name']
            }
            this.loading = false
        },
        async addBankAccount() {
            this.loading = true
            let apiResponse = await ApiService.AddBankAccount(this.name, this.account_no, this.bank, this.account_type);
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                // location.href = ''
                this.getBankAccount()
            }
            this.loading = false
        },
        async getWithdrawals() {
            this.loading = true
            $("button").attr('disabled', true)
            let apiResponse = await ApiService.GetWithdrawals(this.page, this.per_page);
            // console.log('UserFollowership',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.withdrawals = apiResponse['data']
                this.withdrawal_fee = apiResponse['withdrawal_fee']
                this.min_withdrawal = apiResponse['min_withdrawal']
                this.max_withdrawal = apiResponse['max_withdrawal']
                this.total_withdrawal_sum = apiResponse['total_withdrawal_sum']
            }
            this.loading = false
            $("button").attr('disabled', false)
        },
        async withdraw() {
            let amount = parseFloat(this.amount)
            let wallet = parseFloat(this.wallet)
            let min_withdrawal = parseFloat(this.min_withdrawal)
            if(amount > wallet){
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'Insufficient funds',
                });
                return 
            }
            if(amount < min_withdrawal){
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'Min withdrawal is '+this.currency+this.min_withdrawal,
                });
                return 
            }
            $("button").attr('disabled', true)
            this.loading = true
            let apiResponse = await ApiService.Withdraw(this.amount);
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                // location.href = ''
                this.getWithdrawals()
                this.getWallet()
            }
            this.loading = false
            $("button").attr('disabled', false)
        },
        calculateToReceive() {
            if (this.amount > 0 && this.withdrawal_fee > 0) {
                this.toReceive = parseInt((this.amount * ((100 - this.withdrawal_fee) / 100)) * 100) / 100
            }
        },
        calculateAmountToReceive(amount) {
            let toReceive = ''
            if (amount > 0 && this.withdrawal_fee > 0) {
                toReceive = (amount * ((100 - this.withdrawal_fee) / 100))
                toReceive = parseInt(toReceive * 100) / 100
            }
            return toReceive
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #FFF;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
