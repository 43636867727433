<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Current Task</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li>
                    <a href="/dashboard"><i class="fa fa-home"></i></a>
                </li>
            </ol>
        </div>
    </header>
    <div class="panel bg-white p-3" v-if="current_task">
        <h2 class="create-advert-intro">Task: {{ current_task['platform'] }}({{ current_task['type'] }})</h2>
        <h5>
            Task ID: {{ current_task['id'] }}<br />
            User Task ID: {{ userTaskData['id'] }}<br />
            Status: {{ current_task['status'] }}<br />
        </h5>
        <h4 class="text-bold ">
            Please read the instructions and adhere strictly to our rules to avoid payment issues or getting banned.<br />
        </h4>
        <div>
            <h3 v-if="current_task['platform'].toLowerCase() == 'instagram'">Ensure you use the Instagram App to perform this task and always use the Instagram App to perform all ViralTrend Instagram tasks.</h3>
            <h3 v-else></h3>
            <div>
                Task Link: <a target="_blank" :href="current_task['url']">{{ current_task['url'] }}</a>
                <div v-if="current_task['comment']">Comment: {{ current_task['comment'] }}</div>
            </div>
            <div v-if="showInfo == 'true'" class="text-center">
                <br />
                <button v-if="created_at_ts() > secondsWait" class="btn btn-info" v-on:click="showThisTaskInfo('false')">
                    Done
                    <i class="fa fa-arrow-right"></i>
                </button>
                <div v-else>
                    <div class="timer secondsLeft" :data-seconds-left="created_at_ts()"></div>
                    <p>Kindly reload the page after the timer count down if the page does not reload automatically</p>
                </div>
                <span style="padding-left:20px;font-weight:bold;color:red">On no account should you click done when you have not actually done the task to avoid getting banned.</span>
            </div>
        </div>
        <div class="mt-4">
            <br/><br/>
            <div v-html="secondWarning">
            </div>
            <template v-if="created_at_ts() > secondsWait">
                <!-- @if(\Carbon\Carbon::now()->timestamp - $userTaskData['created_at_ts'] > 60) -->
                <div class="">
                    <div v-if="current_task['platform'].toLowerCase() == 'instagram' || current_task['platform'].toLowerCase() == 'tiktok'">
                        <label>
                            {{ current_task['platform'].toUpperCase() }} username(Account username used in performing task)
                        </label><br />
                        <input class="form-control" v-model="socialHandle" :placeholder="current_task['platform'].toUpperCase()+' username used in performing task'" />
                    </div>
                    <div v-else-if="current_task['platform'].toLowerCase() == 'facebook'">
                        <label>
                            {{ current_task['platform'].toUpperCase() }} email(Account email used in performing task)
                        </label><br />
                        <input class="form-control" v-model="socialHandle" :placeholder="current_task['platform'].toUpperCase()+' email used in performing task'" />
                    </div>
                    <div v-else>
                        <label>
                            {{ current_task['platform'].toUpperCase() }} username(Account username used in performing task)
                        </label><br />
                        <input class="form-control" v-model="socialHandle" :placeholder="current_task['platform'].toUpperCase()+' username used in performing task'" />
                    </div>

                    <div>
                        <label>
                            Email Address
                        </label><br />
                        <input class="form-control" v-model="socialEmail" placeholder="Viraltrend user email address" />
                    </div>
                    <div>
                        <br />
                        <button id="sButton" class="btn btn-success" @click="submitSpecialTask">Submit</button>
                    </div>
                </div>
                <div>
                    <br />
                    <button class="btn btn-danger" data-toggle="modal" data-target="#cancelTask">Reject Task</button>
                </div>
            </template>
            <div v-else class="text-center">
                <div >
                    <flip-countdown v-if="task_expire_at" :deadline="task_expire_at" :nowline="now_line" :showDays="false" :showHours="false" @timeElapsed="timeElapsedHandler"></flip-countdown>
                </div>
                <h4>Page will refresh after countdown but if it does not, you can Reload the page manually. Ensure you have read the instructions</h4>
            </div>
            <div class="text-center">
                <br />
                <!-- <button class="btn btn-info" @click="showThisTaskInfo('true')">
                    <i class="fa fa-arrow-left"></i>
                    Read Instructions
                </button> -->
            </div>
        </div>

        <div class="modal  modal-header-color fade" id="cancelTask" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div id="" class="">
                        <section class="panel">
                            <header class="panel-heading">
                                <h2 class="panel-title "><span>Reject Task</span></h2>
                            </header>
                            <div class="panel-body">
                                <div class="viral-card ">
                                    <div>
                                        <label>
                                            Why do you want to reject the task?
                                        </label><br />
                                        <textarea class="form-control" v-model="rejectReason"></textarea>
                                    </div>
                                    <div>
                                        <br />
                                        <button class="btn btn-danger btn-block" @click="rejectSpecialTask">Reject</button>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import moment from "moment";
// import FlipCountdown from 'vue2-flip-countdown'
import FlipCountdown from 'vue2-flip-countdown-plus';

export default {
    name: 'Wallet',
    components: {
        FlipCountdown
    },
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            current_task: '',
            rejectReason: '',
            task: '',
            email: '',
            userTaskData: '',
            socialEmail: '',
            socialHandle: '',
            showInfo: '',
            secondWarning: '',
            task_accepted_at: '',
            task_expire_at: '',
            now_line: '',
            secondWarning: '',
            secondsWait: 30000,
            page: 0,
            per_page: 25,
        }
    },
    beforeMount () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.email = this.thisUser['email']
        this.socialEmail = this.email
        this.getCurrentTask()
    },
    methods: {
        async getCurrentTask() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetCurrentTask();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.current_task = apiResponse['data']
                this.userTaskData = apiResponse['user_task']
                this.secondWarning = apiResponse['second_warning']['value']
                if(this.userTaskData){
                    this.task_accepted_at = this.userTaskData['created_at_ts']
                    if(this.task_accepted_at){
                        this.task_accepted_at = parseInt(this.task_accepted_at/1000)
                        this.now_line = moment().format('Y-MM-D hh:mm:ss')
                        this.task_expire_at = moment.unix(this.task_accepted_at).add({millisecond: this.secondsWait}).format('Y-MM-D hh:mm:ss')
                    }
                }
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: 'Current task not found',
                });
                setTimeout(function () {
                    location.href = '/dashboard';
                }, 2000);
            }
            this.toggleProgress('hide')
        },
        async submitSpecialTask() {
            console.log('not closed');

            this.loadingTasks = true
            let apiResponse = ''
            try {
                $("#sButton").hide()
                apiResponse = await ApiService.PerformUserTask(this.userTaskData['id'], this.socialEmail, this.socialHandle);
            } catch (error) {
                if(error.responseJSON){
                    apiResponse = error.responseJSON
                }
                console.log('PerformUserTask......error',error, apiResponse)
            }
            $("#sButton").show()
            console.log('PerformUserTask',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(function () {
                    location.href = '/dashboard';
                }, 2000);
            }
            this.loadingTasks = false

            this.closeWindow();
        },
        async rejectSpecialTask() {
            console.log('not closed');

            this.loadingTasks = true
            let apiResponse = await ApiService.RejectUserTask(this.userTaskData['id'], this.rejectReason);
            // console.log('GetMe',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(function () {
                    location.href = '/dashboard';
                }, 2000);
            }
            this.loadingTasks = false

            this.closeWindow();
        },
        showThisTaskInfo(a) {

        },
        created_at_ts() {
            let a = 0
            a = moment.now() - parseInt(this.userTaskData['created_at_ts'])
            return a
        },
        closeWindow() {
            console.log('closeWindow')
            // this.nWind.close();
        },
        timeElapsedHandler(){
            console.log('Elapsed')
            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Refreshing screen',
            });
            setTimeout(function () {
                location.href = '';
            }, 2000);
        }
    },
}
</script>

<style>
.bg-white {
    background-color: #fff;
}
.p-3 {
    padding: 10px 15px;
}
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #FFF;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
