<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Profile</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li>
                    <a href="dashboard.html"><i class="fa fa-home"></i></a>
                </li>
            </ol>
        </div>
    </header>
    <div class="row">
        <div class="col-md-4 col-sm-6 col-lg-3">
            <section class="panel">
                <div class="panel-body">
                    <header v-if="currentUser" class="panel-heading bg-primary mb-md">
                        <div class="widget-profile-info">
                            <div class="profile-info">
                                <h4 class="name text-semibold">{{ currentUser.name }}</h4>
                                <h5 class="role text-capitalize">
                                    {{ currentUser.type }} ({{ subscription }})
                                </h5>
                                <div class="profile-footer" style="color: rgb(221, 131, 0)">
                                    <span id="user-state">{{ currentUser.state }}</span>
                                    &nbsp;/&nbsp;
                                    <span id="user-country">{{ currentUser.country }}</span>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div class="widget-toggle-expand mb-md">
                        <div class="widget-header">
                            <h6>Profile Completion</h6>
                            <div class="widget-toggle">+</div>
                        </div>
                        <div class="widget-content-collapsed">
                            <div class="progress progress-xs light">
                                <div role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" class="progress-bar" :style="'width: ' + percent + '%;'">
                                    {{ percent }}%
                                </div>
                            </div>
                        </div>
                        <div class="widget-content-expanded">
                            <ul class="simple-todo-list">
                                <li>Profile Details</li>
                                <li v-if="sc_social == 'true'" class="completed">
                                    Link Social Media Accounts
                                </li>
                                <li v-else>Link Social Media Accounts</li>
                                <li v-if="sc_plan == 'true'" class="completed">
                                    Choose a Plan
                                </li>
                                <li v-else>Choose a Plan</li>
                                <li v-if="sc_task == 'true'" class="completed">
                                    Complete a Task
                                </li>
                                <li v-else>Complete a Task</li>
                                <li v-if="sc_referred == 'true'" class="completed">
                                    Refer Others
                                </li>
                                <li v-else>Refer Others</li>
                            </ul>
                        </div>
                    </div>
                    <hr class="dotted short" />
                    <div class="social-icons-list">
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://facebook.com/+facebook'+facebook" data-original-title="Your Facebook Link">
                            <i class="fa fa-facebook"></i>
                            <span>Facebook</span>
                        </a>
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://www.twitter.com/'+twitter" data-original-title="Your Twitter Link">
                            <i class="fa fa-twitter"></i>
                            <span>Twitter</span>
                        </a>
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://www.instagram.com/'+instagram" data-original-title="Your instagram Link">
                            <i class="fa fa-instagram"></i>
                            <span>Instagram</span>
                        </a>
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://www.youtube.com/channel/'+youtube" data-original-title="Your Youtube Link">
                            <i class="fa fa-youtube"></i>
                            <span>Youtube</span>
                        </a>
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://www.tiktok.com/'+tiktok" data-original-title="Your Tiktok Link">
                            <i class="fa-brands fa-tiktok"></i>
                            <span>Tiktok</span>
                        </a>
                        <a rel="tooltip" data-placement="bottom" target="_blank" :href="'https://www.threads.com/'+threads" data-original-title="Your Threads Link">
                            <i class="fa-brands fa-threads"></i>
                            <span>Threads</span>
                        </a>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4">
            <div class="panel panel-primary">
                <header class="panel-heading">
                    <h2 class="panel-title">Account Plan</h2>
                </header>
                <div class="viral-card viral-yellow text-center">
                    <h4>
                        <strong>{{ subscription }}</strong>
                    </h4>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-4">
            <div class="panel-body">
                <span class="pd-sm pt-sm pr-sm text-bold">BANK DETAILS</span>
                <div class="viral-blue viral-card pb-sm pt-sm pl-sm pr-sm">
                    <p class="pl-md pr-md pt-xs pb-xs mb-sm label label-lg label-primary">
                        Account Name
                    </p>
                    <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px">
                        {{ name }}
                    </p>
                    <br />
                    <hr class="mt-xs mb-xs" />
                    <p class="pl-md pr-md pt-xs pb-xs label label-lg label-primary">
                        Account Number
                    </p>
                    <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px">
                        {{ account_no }}
                    </p>
                    <br />
                    <hr class="mt-xs mb-xs" />
                    <p class="pl-md pr-md pt-xs pb-xs label label-lg label-primary">
                        Bank
                    </p>
                    <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px">
                        {{ bank }}
                    </p>
                    <br />
                    <hr class="mt-xs mb-xs" />
                    <p class="pl-md pr-md pt-xs pb-xs label label-lg label-primary">
                        Account Type
                    </p>
                    <p class="pl-md pr-sm pt-md pb-md label label-lg text-capitalize" style="font-size: 14px">
                        {{ account_type }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row"></div>
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <div class="panel panel-primary">
                <header class="panel-heading">
                    <h2 class="panel-title">Personal Information</h2>
                </header>
                <div class="panel-body">
                    <div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col-6-1">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-user"></i>
                                    </span>
                                    <input type="text" name="firstname" :value="currentUser.name" readonly="readonly" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-6 viral-col-6-1">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-smile-o"></i></span>
                                    <input type="text" name="username" :value="currentUser.username" readonly="readonly" class="form-control" />
                                </div>
                            </div>
                            <div class="col-sm-6 viral-col-6-2">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-flag"></i></span>
                                    <input type="text" name="user-state" :value="currentUser.state" readonly="readonly" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-globe"></i></span>
                                    <input type="text" name="user-country" :value="currentUser.country" readonly="readonly" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-facebook-square fa-3x viral-complete-fb-icon"></i>
                                    </span>
                                    <div v-if="!facebook">
                                        <input id="facebook" name="facebook" placeholder="Facebook User name" form="fb_form" class="form-control" />
                                        <br/>
                                        <button @click="addSocialAccount" form="fb_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br/>
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Twitter Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in Facebook tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-twitter-square fa-3x viral-complete-tw-icon"></i>
                                    </span>
                                    <div v-if="!twitter">
                                        <input id="twitter" name="twitter" placeholder="Twitter Username" form="fb_form" class="form-control" />
                                        <br/>
                                        <button @click="addSocialAccount" form="fb_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br/>
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Twitter Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in Twitter tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-instagram fa-3x viral-complete-in-icon"></i>
                                    </span>
                                    <div v-if="!instagram">
                                        <input id="instagram" name="instagram" placeholder="Instagram Username" form="fb_form" class="form-control" />
                                        <br/>
                                        <button @click="addSocialAccount" form="fb_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br/>
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Instagram Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in Instagram tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-youtube-square fa-3x viral-complete-yb-icon"></i>
                                    </span>
                                    <div v-if="!youtube">
                                        <input id="youtube" name="youtube" placeholder="Youtube Username" form="fb_form" class="form-control" />
                                        <br/>
                                        <button @click="addSocialAccount" form="fb_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br/>
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Youtube Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in Youtube tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <img src="/images/ti.png" style="height: 40px" />
                                    </span>
                                    <div v-if="!tiktok">
                                        <input id="tiktok" name="tiktok" placeholder="TikTok Username" form="tt_form" class="form-control" />
                                        <br />
                                        <button @click="addSocialAccount" form="tt_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br />
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Tiktok Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in TikTok tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 mb-xs mt-xs">
                                <div class="input-group panel-body link-social-item">
                                    <span class="input-group-addon viral-input-addon">
                                        <img src="/images/threads.png" style="height: 40px;width: 30px; object-fit: contain;" />
                                    </span>
                                    <div v-if="!threads">
                                        <input id="threads" name="threads" placeholder="Threads Username" form="tt_form" class="form-control" />
                                        <br />
                                        <button @click="addSocialAccount" form="tt_form" class="btn btn-primary btn-block">
                                            Update
                                        </button>
                                        <br />
                                    </div>
                                    <template v-else>
                                        <i rel="tooltip" data-placement="top" data-original-title="Threads Linked" class="fa fa-check fa-2x pull-right mb-xs mt-sm mr-xs btn-sm complete-account-success-icon"></i>
                                        <span class="note-account-info mt-sm">Link account to partake in Thread tasks.</span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <!-- <button type="submit" class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center">Update Profile</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="panel panel-primary">
                <header class="panel-heading">
                    <h2 class="panel-title">Bank Account Information</h2>
                </header>
                <div class="panel-body">
                    <div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-edit"></i>
                                    </span>
                                    <input type="text" id="user_account_name" v-model="name" placeholder="Update Account Name" required="required" minlength="4" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-pencil-square"></i>
                                    </span>
                                    <input type="tel" id="user_account_number" v-model="account_no" placeholder="Update Account Number" required="required" minlength="10" maxlength="10" length="10" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group" v-if="currentUser && currentUser.country">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-bank"></i></span>
                                    <select v-if="currentUser.country.toLowerCase() == 'nigeria'" v-model="bank" name="bank-select" id="bank" required="required" class="form-control populate">
                                        <option value="" selected="selected">
                                            - Choose Your Bank -
                                        </option>
                                        <option value="access">Access Bank</option>
                                        <option value="citibank">Citibank</option>
                                        <option value="diamond">Diamond Bank</option>
                                        <option value="ecobank">Ecobank</option>
                                        <option value="fidelity">Fidelity Bank</option>
                                        <option value="fbn">First Bank of Nigeria</option>
                                        <option value="fcmb">
                                            First City Monument Bank (FCMB)
                                        </option>
                                        <option>FSDH Merchant Bank</option>
                                        <option value="gtb">Guarantee Trust Bank (GTB)</option>
                                        <option value="heritage">Heritage Bank</option>
                                        <option value="Keystone">Keystone Bank</option>
                                        <option>Jaiz Bank Plc</option>
                                        <option value="rand">Rand Merchant Bank</option>
                                        <option value="skye">Skye Bank (Polaris Bank)</option>
                                        <option value="stanbic">Stanbic IBTC Bank</option>
                                        <option value="standard">Standard Chartered Bank</option>
                                        <option value="sterling">Sterling Bank</option>
                                        <option value="suntrust">Suntrust Bank</option>
                                        <option value="union">Union Bank</option>
                                        <option value="uba">United Bank for Africa (UBA)</option>
                                        <option value="unity">Unity Bank</option>
                                        <option value="wema">Wema Bank</option>
                                        <option value="zenith">Zenith Bank</option>
                                    </select>
                                    <select v-else-if="currentUser.country.toLowerCase() == 'ghana'" v-model="bank" class="form-control populate" name="bank-select" id="bank" required>
                                        <option value="" selected="selected">
                                            - Choose Your Bank -
                                        </option>
                                        <optgroup label="Access Bank (Ghana) Limited">
                                            <option>ACCESS BANK - KASOA BRANCH</option>
                                            <option>ACCESS BANK GH LTD KANESHIE</option>
                                            <option>ACCESS BANK GH LTD LASHIBI</option>
                                            <option>ACCESS BANK GH LTD MADINA</option>
                                            <option>ACCESS BANK GH LTD ,OSU WATSON</option>
                                            <option>ACCESS BANK GH LTD ,TEMA MAIN</option>
                                            <option>ACCESS BANK GH LTD ABEKA LAPAZ</option>
                                            <option>ACCESS BANK GH LTD ACHINOTA BRANCH</option>
                                            <option>ACCESS BANK GH LTD ADUM</option>
                                            <option>ACCESS BANK GH LTD AIRPORT</option>
                                            <option>ACCESS BANK GH LTD AMAKOM</option>
                                            <option>ACCESS BANK GH LTD ASHAIMAN</option>
                                            <option>ACCESS BANK GH LTD CASTLE ROAD</option>
                                            <option>ACCESS BANK GH LTD ENCHI</option>
                                            <option>ACCESS BANK GH LTD KANESHIE POST OFFICE</option>
                                            <option>ACCESS BANK GH LTD KANTAMANTO</option>
                                            <option>ACCESS BANK GH LTD KUMASI ASAFO</option>
                                            <option>ACCESS BANK GH LTD NIMA</option>
                                            <option>
                                                ACCESS BANK GH LTD NORTH INDUSTRIAL AREA
                                            </option>
                                            <option>ACCESS BANK GH LTD OKAISHIE</option>
                                            <option>ACCESS BANK GH LTD OSU BRANCH</option>
                                            <option>ACCESS BANK GH LTD RING ROAD CENTRAL</option>
                                            <option>ACCESS BANK GH LTD SEFWI WIAWSO</option>
                                            <option>
                                                ACCESS BANK GH LTD SOUTH INDUDTRIAL AREA
                                            </option>
                                            <option>ACCESS BANK GH LTD SUAME</option>
                                            <option>ACCESS BANK GH LTD TAKORADI</option>
                                            <option>ACCESS BANK GH LTD TAMALE</option>
                                            <option>ACCESS BANK GH LTD TARKWA</option>
                                            <option>ACCESS BANK GH LTD TECHIMAN</option>
                                            <option>ACCESS BANK GH LTD TEMA COMM 1</option>
                                            <option>ACCESS BANK GH LTD TEMA INDUSTRIAL AREA</option>
                                            <option>Access Bank Main Branch</option>
                                            <option>ACCESS BANK-NEWTOWN BRANCH</option>
                                            <option>ACCESS BANK North Industrial Area</option>
                                        </optgroup>
                                        <optgroup label="AGRICULTURAL DEVELOPMENT BANK">
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK - AGONA NKWANTA
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK - BOLE</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK - BUIPE</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK - DANQUAH CIRCLE
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK - GREL</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK - KALADAN</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK - MANKESSIM
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK - NIMA</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK - UCC BRANCH
                                            </option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK -INTERNATIONAL BANKING
                                            </option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK KADE BRANCH
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK KENYASI</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK MAKOLA BRANCH
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK NKWANTA</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK SPINTEX ROAD
                                            </option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK- ASIAKWA</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK- HO</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK- KASOA</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK- WINNBA</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK- YENDI</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK-AMAKOM</option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK-KUMASI CENTRALMARKET
                                            </option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK-KUMASI PREMPEH 11 STREET
                                            </option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK-TAKORADI HARBOUR
                                            </option>
                                            <option>
                                                AGRICULTURAL DEVELOPMENT BANK-HEAD OFFICE
                                            </option>
                                            <option>AGRICULTURAL DEV BANK - KPANDO</option>
                                            <option>AGRICULTURAL DEV BANK -ASHIAMAN</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK TUMU</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK WALEWALE</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK -WA</option>
                                            <option>AGRICULTURAL DEVELOPMENT BANK-SOGAKOPE</option>
                                            <option>AGRICULTURAL DEV BANK LTD-TEMA MERIDIAN</option>
                                            <option>AGRICULTURAL DEV BANK SAVELUGU</option>
                                            <option>AGRICULTURAL DEV BK-ABEKALAPAZ</option>
                                            <option>AGRICULTURAL DEV BK-ADABRAKAH</option>
                                            <option>AGRICULTURAL DEV BK-ADB HOUSE BRANCH</option>
                                            <option>AGRICULTURAL DEV BK-AGONA SWEDRU</option>
                                            <option>AGRICULTURAL DEV BK-ASSIN FOSO</option>
                                            <option>AGRICULTURAL DEV BK-ATEBUBU</option>
                                            <option>AGRICULTURAL DEV BK-BAWKU</option>
                                            <option>AGRICULTURAL DEV BK-BEREKUM</option>
                                            <option>AGRICULTURAL DEV BK-BKWAI</option>
                                            <option>AGRICULTURAL DEV BK-BOLGATANGA</option>
                                            <option>AGRICULTURAL DEV BK-BONSO NKWANTA</option>
                                            <option>AGRICULTURAL DEV BK-CAPE COAST</option>
                                            <option>AGRICULTURAL DEV BK-CEDI HOUSE</option>
                                            <option>AGRICULTURAL DEV BK-DANSOMAN</option>
                                            <option>AGRICULTURAL DEV BK-DENU</option>
                                            <option>AGRICULTURAL DEV BK-DORMAA AHENKRO</option>
                                            <option>AGRICULTURAL DEV BK-ENCHI</option>
                                            <option>AGRICULTURAL DEV BK-GOASO</option>
                                            <option>AGRICULTURAL DEV BK-GULF HOUSE</option>
                                            <option>AGRICULTURAL DEV BK-HOHOE</option>
                                            <option>AGRICULTURAL DEV BK-JUAPONG</option>
                                            <option>AGRICULTURAL DEV BK-KANESSHIE</option>
                                            <option>AGRICULTURAL DEV BK-KOFORIDUA</option>
                                            <option>AGRICULTURAL DEV BK-KORKORDZOR</option>
                                            <option>AGRICULTURAL DEV BK-KUMASI ADUM</option>
                                            <option>AGRICULTURAL DEV BK-KUMASI ASOKWA</option>
                                            <option>AGRICULTURAL DEV BK-KWAPONG</option>
                                            <option>AGRICULTURAL DEV BK-MADINA</option>
                                            <option>AGRICULTURAL DEV BK-NEW EDUBIASE</option>
                                            <option>AGRICULTURAL DEV BK-NKAWKAW</option>
                                            <option>AGRICULTURAL DEV BK-NKORANSAH</option>
                                            <option>AGRICULTURAL DEV BK-NUNGUA</option>
                                            <option>AGRICULTURAL DEV BK-OBUASI</option>
                                            <option>AGRICULTURAL DEV BK-OSU</option>
                                            <option>AGRICULTURAL DEV BK-RING ROAD CENTRAL</option>
                                            <option>AGRICULTURAL DEV BK-SEFWI ESSAM</option>
                                            <option>AGRICULTURAL DEV BK-SEFWI WIAWSO</option>
                                            <option>AGRICULTURAL DEV BK-SUHUM</option>
                                            <option>AGRICULTURAL DEV BK-SUNYANI</option>
                                            <option>AGRICULTURAL DEV BK-TAKORADI</option>
                                            <option>AGRICULTURAL DEV BK-TAMALE</option>
                                            <option>AGRICULTURAL DEV BK-TAMALE ABOABO</option>
                                            <option>AGRICULTURAL DEV BK-TECHIMAN</option>
                                            <option>AGRICULTURAL DEV BK-TEMA</option>
                                            <option>AGRICULTURAL DEV BK-TESHIE</option>
                                            <option>AGRICULTURAL DEV BK-WA</option>
                                            <option>AGRICULTURAL DEV. BANK-ACCRA NEW TOWN</option>
                                            <option>AGRICULTURAL DEV. BANK-ACHIMOTA</option>
                                            <option>AGRICULTURAL DEV. BANK-SAVELUGU</option>
                                            <option>AGRICULTURAL DEVT BANK- NAVRONGO BRANCH</option>
                                        </optgroup>
                                        <optgroup label="AMALGAMATED BANK LTD">
                                            <option>AMALGAMATED BANK-ACCRA CENTRAL</option>
                                            <option>AMALGAMATED BANK-ACCRA NEWTOWN</option>
                                            <option>AMALGAMATED BANK-DANSOMAN</option>
                                            <option>AMALGAMATED BANK-FARRAR</option>
                                            <option>AMALGAMATED BANK-KUMASI</option>
                                            <option>AMALGAMATED BANK-KWASHIEMAN</option>
                                            <option>AMALGAMATED BANK-KWASHIEMAN</option>
                                            <option>AMALGAMATED BANK-MAAMOBI</option>
                                            <option>AMALGAMATED BANK-MICHEL CAMP</option>
                                            <option>AMALGAMATED BANK-OSU</option>
                                            <option>AMALGAMATED BANK-SPINTEX ROAD</option>
                                            <option>AMALGAMATED BANK-SPINTX ROAD</option>
                                            <option>AMALGAMATED BANK-TAKORADI</option>
                                            <option>Bank of Africa Bank (Gh) Ltd</option>
                                            <option>BANK OF AFRICA KUMASI ADUM</option>
                                            <option>BANK OF AFRICA, TEMA BRANCH</option>
                                            <option>BANK OF AFRICA- MADINA</option>
                                            <option>BANK OF AFRICA-ABOSSEY OKAI</option>
                                            <option>
                                                BANK OF AFRICA-MULTICREDIT SAV AND LOANS
                                            </option>
                                        </optgroup>
                                        <optgroup label="ARB APEX BANK LTD">
                                            <option>ABOKOBI RURAL BANK LTD-ABOKOBI</option>
                                            <option>ADA RURAL BANK LTD-KASSEH</option>
                                            <option>ADANSI RURAL BANK LTD-FOMENA</option>
                                            <option>ADONTEN COMMUNITY BANK LTD-NEW TAFO</option>
                                            <option>AFRAM RURAL BANK LTD-TEASE</option>
                                            <option>AGAVE RURAL BANK LTD-DABALE</option>
                                            <option>AGONA RURAL BANK LTD-KWANYAKU</option>
                                            <option>AHAFO ANO PREMIER RURAL BANK LTD-WIOSO</option>
                                            <option>AHAFO COMMUNITY RURAL BANK LTD-KUKUOM</option>
                                            <option>AHANTAMAN RURAL -AGONA NKWANTA</option>
                                            <option>AKATAKYIMAN RURAL BANK LTD-KOMENDA</option>
                                            <option>AKIM BOSOME RURAL BANK LTD-AKIM SWEDRU</option>
                                            <option>AKOTI RURAL BANK LTD-ASSIN AKROPONG</option>
                                            <option>AKROFUOM AREA RURAL BANK LTD-AKROFUOM</option>
                                            <option>AKUAPEM RURAL BANK LTD-MAMFE</option>
                                            <option>AKYEM MANSA RURAL BANK LTD-AYIREBI</option>
                                            <option>AKYEPIM RURAL BANK LTD-GOMOA-DAW</option>
                                            <option>AMANANO RURAL BANK LTD-NYINAHIM</option>
                                            <option>AMASIE WEST RURAL BANK LTD-ANTOAKROM</option>
                                            <option>AMATIN AND KASEI COMMUNITY BANK -KASEI</option>
                                            <option>AMENFIMAN RURAL BANK-WASSA AKROPONG</option>
                                            <option>AMUM RURAL BANK LTD-ANUM</option>
                                            <option>ANKOBRA WEST (ESIAMA) RURAL -ESIAMA</option>
                                            <option>ANLO RURAL BANK LTD-ANLOGA</option>
                                            <option>APEX BANK - BOLGA CENTRE-BOLGATANGA</option>
                                            <option>APEX BANK - CAPE COASTCAPE COAST</option>
                                            <option>APEX BANK - TAMALE CENTRE-TAMALE</option>
                                            <option>APEX BANK -HOHOE CENTRE-HOHOE</option>
                                            <option>APEX BANK -SUNYANI CENTRE-SUNYANI</option>
                                            <option>APEX BANK -WA CENTRE-WA</option>
                                            <option>APEX BANK KOFORIDUA CENTRE-KOFORIDUA</option>
                                            <option>APEX BANK-HO CENTRE-HO</option>
                                            <option>APEX BANK-KUMASIKUMASI</option>
                                            <option>APEX BANK-TAKORADI CENTRE-TAKORADI</option>
                                            <option>APEX BANKACCRA CENTRE-ACCRA</option>
                                            <option>APEX LINK CENTRE-CEDI HOUSECED HOUSE</option>
                                            <option>ASANTE AKYEM RURAL BANK LTD-JUANSA</option>
                                            <option>ASAWINSO RURAL BANK LTD-SEFWI-ASAWINSO</option>
                                            <option>ASOKORE RURAL BANK LTD-ASOKRE</option>
                                            <option>ASSINMAN RURAL BANK LTD-ASSIN MANSO</option>
                                            <option>ASUBONTEN RURAL BANK LTD-WORAWORA</option>
                                            <option>ASUOGYAMAN RURAL BANK LTD-ACCRA</option>
                                            <option>ASUOPRA RURAL BANK LTD-AFOSU</option>
                                            <option>ASUTIFI RURAL BANK LTD-ACERENSUA</option>
                                            <option>ATIWA RURAL BANK LTD-KWABENG</option>
                                            <option>ATOBIASE AREA RURAL BANK LTD-ATOBIASE</option>
                                            <option>ATWEABAN RURAL BANK LTD-SUNYANI</option>
                                            <option>ATWIMA KWANWOMA RURAL BANK-PAKYI NO. 2</option>
                                            <option>ATWIMA MPONUA RURAL BANK LTD-TOASE</option>
                                            <option>ATWIMA RURAL BANK LTD-FOASE</option>
                                            <option>AWUTU EMASA RURAL BANK LTD-AWUMASA</option>
                                            <option>BADUMAN RURAL BANK LTD-BADU</option>
                                            <option>
                                                BAWJIASE AREA RURAL BANK -AWUTU BAWJIASE
                                            </option>
                                            <option>BESSFA RURAL BANK LTD-GARU</option>
                                            <option>BIA-TORYA RURAL BANK LTD</option>
                                            <option>BOMAA AREA RURAL BANK LTD-BOMAA</option>
                                            <option>BONGO RURAL BANK LTD-BOLGATANGA</option>
                                            <option>BONZALI RURAL BANK LTD-KUMBUNGU</option>
                                            <option>BORIMANGA RURAL BANK LTD-SAVELEGU</option>
                                            <option>BOSOME FREHO RURAL BANK LTD-KUMASI</option>
                                            <option>BOSOMTWI RURAL BANK LTD-KUNTANASE</option>
                                            <option>BRAKWA BRAMAN RURAL BANK LTD-BRAKWA</option>
                                            <option>BUILSA COMMUNITY BANK LTD-SANDEMA</option>
                                            <option>BUTAWU RURAL BANK LTD-HO</option>
                                            <option>BUWUULONSO RURAL BANK LTD-TAMALE</option>
                                            <option>CAPITAL RURAL BANK LTD-ABESIM</option>
                                            <option>CITIZEN RURAL BANK LTD-ACCRA</option>
                                            <option>DANGBE RURAL BANK LTD-PRAMPRAM</option>
                                            <option>DENKYIRAMAN RURAL BANK LTD-AYAMFURI</option>
                                            <option>DERMA RURAL BANK LTD-DERMA</option>
                                            <option>DROBO COMMUNITY RURAL BANK LTD-DROBO</option>
                                            <option>DUMPONG RURAL BANK LTD-OFRAMASE</option>
                                            <option>EAST MANPRUSI COMMUNITY -GAMBAGA</option>
                                            <option>
                                                EASTERN GOMOA ASSIN RURAL-GOMOA-DOMINASE
                                            </option>
                                            <option>EJURAMAN RURAL BANK LTD-KUMASI</option>
                                            <option>EKUMFIMAN RURAL BANK LTD-ESSUEHYIA</option>
                                            <option>
                                                ENYAN DENKYIRA RURAL BANk-ENYAN DENKYIRA
                                            </option>
                                            <option>FANTEAKWA RURAL BANK LTD-KOFORIDUA</option>
                                            <option>FIAGYA RURAL BANK LTD-BUSUNYA</option>
                                            <option>FIASEMAN (BOGOSO AREA) RURAL -BOGOSO</option>
                                            <option>GA RURAL BANK LTD-AMASAMAN</option>
                                            <option>GBI RURAL BANK LTD-HOHOE</option>
                                            <option>GOMOA AJUMAKO RURAL BANK LTD-AFRANSI</option>
                                            <option>GOMOA RURAL BANK LTD-APAM</option>
                                            <option>HEAD OFFICE-SOUTH RIDGE ACCRA</option>
                                            <option>JOMORO RURAL BANK LTD-TIKOBO NO. 1</option>
                                            <option>JUABEN RURAL BANK LTD-JUABEN</option>
                                            <option>KAASEMAN RURAL BANK LTD-KAASE</option>
                                            <option>KAKUM RURAL BANK LTD-ELMINA</option>
                                            <option>KINTAMPO RURAL BANK LTD-KINTAMPO</option>
                                            <option>KPASSA RURAL BANK LTD-HOHOE</option>
                                            <option>KUMAWUMAN RURAL BANK LTD-KUMAWU</option>
                                            <option>KWAEBIBIREM RURAL BANK LTD-ASUOM</option>
                                            <option>KWAHU PRASO RURAL BANK LTD-KWAHU PRASO</option>
                                            <option>KWAHU RURAL BANK LTD-KWAHU PEPEASE</option>
                                            <option>KWAMANMAN RURAL BANK LTD-KWAMANG</option>
                                            <option>LA COMMUNITY BANK LTD-LA</option>
                                            <option>LAWRA RURAL BANK LTD-WA</option>
                                            <option>LOWER PRA RURAL BANK LTD-SHAMA</option>
                                            <option>MANSOMAN RURAL BANK LTD-MANSO-AMENFI</option>
                                            <option>MANYA KROBO RURAL BANK-ODUMASE-KROBO</option>
                                            <option>MEPE AREA RURAL BANK LTD-MEPE</option>
                                            <option>MFANTSIMAN COMMUNITY RURAL -BIRIWA</option>
                                            <option>MICROFIN RURAL BANK -MICROFIN</option>
                                            <option>MPONUA RURAL BANK LTD-AMUANA PRASO</option>
                                            <option>MUMUADA RURAL BANK LTD-OSINO</option>
                                            <option>NAARA RURAL BANK LTD-PAGA</option>
                                            <option>NAFANA RURAL BANK LTD-SAMPA</option>
                                            <option>NANDOM RURAL BANK LTD-NANDOM</option>
                                            <option>NKORANMAN RURAL BANK LTD-SEIKWA</option>
                                            <option>NKORANZA KWABRE RURAL BANK LTD-AKUMA</option>
                                            <option>NKRANKWANTA RURAL BANK LTD-SUNYANI</option>
                                            <option>NORTH TONGU RURAL BANK LTD-ADIDOME</option>
                                            <option>NORTH VOLTA RURAL BANK LTD-GUAMAN</option>
                                            <option>NSOATREMAN RURAL BANK LTD-NSOATRE</option>
                                            <option>NSUTAMAN RURAL BANK LTD-NSUTA</option>
                                            <option>NWABIAGYA RURAL BANK LTD-BAREKESE</option>
                                            <option>NYAKROM RURAL BANK LTD-NYAKROM</option>
                                            <option>NYANKUMASE AHENKRO RURAL-FANTI NYANKUMA</option>
                                            <option>NZEMA MANLE RURAL BANK LTD-AWIEBO</option>
                                            <option>ODOTOBRI RURAL BANK LTD-JACOBU</option>
                                            <option>ODUPONGKPEHE RURAL BANK LTD-KASOA</option>
                                            <option>ODWEN-ANOMA RURAL BANK LTD-KWEEHWEE</option>
                                            <option>OFFINSO RURAL BANK LTD-KUMASI</option>
                                            <option>OKOMFO ANOKYE RURAL BANK LTD-WIAMOASE</option>
                                            <option>OTUASEKAN RURAL BANK LTD-KOFIASE</option>
                                            <option>SEFWIMAN RURAL BANK LTD-SEFWI-ASAW</option>
                                            <option>SEKYEDUMASI RURAL BANK LTD-SEKYEDUMASI</option>
                                            <option>SEKYERE RURAL BANK LTD-JAMASI</option>
                                            <option>SHAI RURAL BANK LTD-DODOWA</option>
                                            <option>SISSALA RURAL BANK LTD-TUMA</option>
                                            <option>SONZELE RURAL BANK LTD-JIRAPA</option>
                                            <option>SOUTH AKIM RURAL BANK LTD-NANKESE</option>
                                            <option>SOUTH BIRIM RURAL BANK LTD-ACHIASE</option>
                                            <option>SUMA RURAL BANK LTD-SUMA AHENKRO</option>
                                            <option>TANO AGYA RURAL BANK LTD-DADIESOABA</option>
                                            <option>TANO RURAL BANK LTD-NTROSO-GYEDU</option>
                                            <option>TEPAMAN RURAL BANK-TEPAMAN</option>
                                            <option>TIZAA RURAL BANK LTD-TAMALE</option>
                                            <option>TOENDE RURAL BANK LTD-ZIBILLA</option>
                                            <option>TWIFO RURAL BANK LTD-TWIFO AGONA</option>
                                            <option>UNIONRURAL BANK LTD-SENYA BREKU</option>
                                            <option>UNITY RURAL BANK LTD-ZIOPE</option>
                                            <option>UPPER AMENFI RURAL BANK LTD-ANKWAWSO</option>
                                            <option>UPPER MANYA KRO RURAL BANK LTD-ASESEWA</option>
                                            <option>WAMFIE RURAL BANK LTD-WAMFIE</option>
                                            <option>WENCHI RURAL BANK LTD-WENCHI</option>
                                            <option>WEST MANPRUSI COMMUNITY-WALEWALE</option>
                                            <option>WESTERN RURAL BANK LTD-WASSA AKROPONG</option>
                                            <option>WETO RURAL BANK LTD-KPEVE</option>
                                            <option>YAA ASANTEWAA RURAL BANK-YAA ASANTEWAA</option>
                                            <option>YAPRA RURAL BANK LTD-PRANG</option>
                                            <option>ZABZUGU RURAL BANK LTD</option>
                                        </optgroup>
                                        <optgroup label="BANK OF BARODA">
                                            <option>BANK OF BARODA (GH) LTD-HEAD OFFICE</option>
                                            <option>BANK OF BARODA GH LTD TEMA</option>
                                        </optgroup>
                                        <optgroup label="BANK OF GHANA">
                                            <option>BANK OF GHANA-ACCRA</option>
                                            <option>BANK OF GHANA-AGONA SWEDRU</option>
                                            <option>BANK OF GHANA-HOHOE</option>
                                            <option>BANK OF GHANA-KUMASI</option>
                                            <option>BANK OF GHANA-SEFWI BOAKO</option>
                                            <option>BANK OF GHANA-SUNYANI</option>
                                            <option>BANK OF GHANA-TAKORADI</option>
                                            <option>BANK OF GHANA-TAMALE</option>
                                        </optgroup>
                                        <optgroup label="BARCLAYS BANK">
                                            <option>BARCLAYS BANK (GH) LTD -GUMANI</option>
                                            <option>BARCLAYS BANK (GH) LTD-A AND C MALL</option>
                                            <option>BARCLAYS BANK (GH) LTD-ABEKA LAPAZ</option>
                                            <option>BARCLAYS BANK (GH) LTD-ACCRA MALL</option>
                                            <option>BARCLAYS BANK (GH) LTD-ACHIMOTA</option>
                                            <option>BARCLAYS BANK (GH) LTD-ADUM</option>
                                            <option>BARCLAYS BANK (GH) LTD-AGBOBLOSHIE</option>
                                            <option>BARCLAYS BANK (GH) LTD-AHODWO</option>
                                            <option>BARCLAYS BANK (GH) LTD-AIRPORT CITY</option>
                                            <option>BARCLAYS BANK (GH) LTD-ASAFO</option>
                                            <option>BARCLAYS BANK (GH) LTD-ASANKRAGUA</option>
                                            <option>BARCLAYS BANK (GH) LTD-ASHAIMAN</option>
                                            <option>BARCLAYS BANK (GH) LTD-ATEBUBU</option>
                                            <option>BARCLAYS BANK (GH) LTD-AVENUE CENTRAL</option>
                                            <option>BARCLAYS BANK (GH) LTD-BAWKU</option>
                                            <option>BARCLAYS BANK (GH) LTD-BCM</option>
                                            <option>BARCLAYS BANK (GH) LTD-BEREKUM</option>
                                            <option>BARCLAYS BANK (GH) LTD-BOLGATANGA</option>
                                            <option>BARCLAYS BANK (GH) LTD-CAPE COAST</option>
                                            <option>BARCLAYS BANK (GH) LTD-DANSOMAN</option>
                                            <option>BARCLAYS BANK (GH) LTD-DARKUMAN</option>
                                            <option>BARCLAYS BANK (GH) LTD-ELUBO</option>
                                            <option>BARCLAYS BANK (GH) LTD-GNPC</option>
                                            <option>BARCLAYS BANK (GH) LTD-GOASO</option>
                                            <option>BARCLAYS BANK (GH) LTD-HEAD OFFICE</option>
                                            <option>BARCLAYS BANK (GH) LTD-HIGH STREET</option>
                                            <option>BARCLAYS BANK (GH) LTD-HO</option>
                                            <option>BARCLAYS BANK (GH) LTD-HOHOE</option>
                                            <option>BARCLAYS BANK (GH) LTD-KANESHIE</option>
                                            <option>BARCLAYS BANK (GH) LTD-KASOA</option>
                                            <option>BARCLAYS BANK (GH) LTD-KEJETIA- KUMASI</option>
                                            <option>BARCLAYS BANK (GH) LTD-KNUTSFORD AVENUE</option>
                                            <option>BARCLAYS BANK (GH) LTD-KOFORIDUA</option>
                                            <option>BARCLAYS BANK (GH) LTD-KROFOM</option>
                                            <option>BARCLAYS BANK (GH) LTD-LEGON</option>
                                            <option>BARCLAYS BANK (GH) LTD-LEGON MAIN</option>
                                            <option>BARCLAYS BANK (GH) LTD-MAAMOBI</option>
                                            <option>BARCLAYS BANK (GH) LTD-MAKOLA SQUARE</option>
                                            <option>BARCLAYS BANK (GH) LTD-MANKESSIM</option>
                                            <option>BARCLAYS BANK (GH) LTD-MATAHEKO</option>
                                            <option>BARCLAYS BANK (GH) LTD-MOTORWAY EXT</option>
                                            <option>BARCLAYS BANK (GH) LTD-NIMA</option>
                                            <option>BARCLAYS BANK (GH) LTD-NORTH KANESHIE</option>
                                            <option>BARCLAYS BANK (GH) LTD-NSAWAM</option>
                                            <option>BARCLAYS BANK (GH) LTD-OBUASI</option>
                                            <option>BARCLAYS BANK (GH) LTD-ODA</option>
                                            <option>BARCLAYS BANK (GH) LTD-OFFSHORE BANKING</option>
                                            <option>BARCLAYS BANK (GH) LTD-OSU</option>
                                            <option>
                                                BARCLAYS BANK (GH) LTD-PREMPEH II STREET
                                            </option>
                                            <option>
                                                BARCLAYS BANK (GH) LTD-RING ROAD CENTRAL
                                            </option>
                                            <option>BARCLAYS BANK (GH) LTD-SME CENTRE</option>
                                            <option>BARCLAYS BANK (GH) LTD-SOMANYA</option>
                                            <option>BARCLAYS BANK (GH) LTD-SPINTEX MAIN</option>
                                            <option>BARCLAYS BANK (GH) LTD-SUNYANI</option>
                                            <option>BARCLAYS BANK (GH) LTD-TAFO PANKRONO</option>
                                            <option>BARCLAYS BANK (GH) LTD-TAMALE</option>
                                            <option>BARCLAYS BANK (GH) LTD-TANOSO</option>
                                            <option>BARCLAYS BANK (GH) LTD-TARKWA</option>
                                            <option>BARCLAYS BANK (GH) LTD-TARKWA MINES</option>
                                            <option>BARCLAYS BANK (GH) LTD-TECHIMAN</option>
                                            <option>BARCLAYS BANK (GH) LTD-TEMA MAIN</option>
                                            <option>
                                                BARCLAYS BANK (GH) LTD-TEMA OIL REFINERY
                                            </option>
                                            <option>BARCLAYS BANK (GH) LTD-TEPA</option>
                                            <option>BARCLAYS BANK (GH) LTD-UNDP</option>
                                            <option>BARCLAYS BANK (GH) OLD SUAME MAGAZINE</option>
                                            <option>BARCLAYS BANK (GH) TEMA FISHING HARBOUR</option>
                                            <option>BARCLAYS BANK CORPORATE SERVICE CENTR</option>
                                            <option>BARCLAYS BANK GH LTD CIRCLE</option>
                                            <option>BARCLAYS BANK GHANA LTD - WINNEBA</option>
                                            <option>BARCLAYS BANK HIGH STREET-TAKORADI</option>
                                            <option>BARCLAYS BANK INDEPENDENCE AVENUE</option>
                                            <option>BARCLAYS BANK LIBERATION ROAD-TAKORADI</option>
                                            <option>BARCLAYS BANK(GH) LTD-ABOSSEY OKAI</option>
                                            <option>BARCLAYS BANK(GH) LTD-ADENTA</option>
                                            <option>BARCLAYS BANK(GH) LTD-AFLAO</option>
                                            <option>BARCLAYS BANK(GH) LTD-AGOGO</option>
                                            <option>BARCLAYS BANK(GH) LTD-BANTAMA</option>
                                            <option>BARCLAYS BANK(GH) LTD-DIRECT SALES</option>
                                            <option>BARCLAYS BANK(GH) LTD-DOME</option>
                                            <option>BARCLAYS BANK(GH) LTD-HAATSO</option>
                                            <option>BARCLAYS BANK(GH) LTD-KONONGO</option>
                                            <option>BARCLAYS BANK(GH) LTD-KOTOBABI</option>
                                            <option>BARCLAYS BANK(GH) LTD-MADINA</option>
                                            <option>BARCLAYS BANK(GH) LTD-NKAWKAW</option>
                                            <option>BARCLAYS BANK(GH) LTD-SEFWI-WIASO</option>
                                            <option>BARCLAYS BANK(GH) LTD-SPINTEX PRESTIGE</option>
                                            <option>BARCLAYS BANK(GH) LTD-TAKORADI KOKOMPE</option>
                                            <option>BARCLAYS BANK(GH) LTD-TESHIE NUNGUA</option>
                                            <option>BARCLAYS BANK(GH) LTD-TWIFO PRASO</option>
                                            <option>BARCLAYS BANK(GH) LTD-WA</option>
                                            <option>BARCLAYS BANK(GH) LTD-WEIJA</option>
                                            <option>BARCLAYS BANK(GH) LTD-WINNEBA</option>
                                            <option>BARCLAYS BANK(GH)LTD HOHOE</option>
                                            <option>BARCLAYS BANK(GH)LTD-ACCRA NEWTOWN</option>
                                            <option>BARCLAYS BANK(GH)LTD-PALM WINE JUNCTION</option>
                                            <option>BARCLAYS BANK(GH)NEW SUAME MAGAZINE</option>
                                            <option>BARCLAYS BANKOBUASI BRANCH</option>
                                        </optgroup>
                                        <optgroup label="BSIC GHANA LTD">
                                            <option>BISC GHANA LTD-NORTH INDUSTRIAL AREA</option>
                                            <option>BSIC GHANA LTD TAKORADI BRANCH</option>
                                            <option>BSIC GHANA LTD-ACCRA CENTRAL</option>
                                            <option>BSIC GHANA LTD-ACHIMOTA</option>
                                            <option>BSIC GHANA LTD-ADABRAKA</option>
                                            <option>BSIC GHANA LTD-DARKUMAN</option>
                                            <option>BSIC GHANA LTD-HEAD OFFICE</option>
                                            <option>BSIC GHANA LTD-KUMASI</option>
                                            <option>BSIC GHANA LTD-KUMASI (ALABAR)</option>
                                            <option>BSIC GHANA LTD-MADINA</option>
                                            <option>BSIC GHANA LTD-NIMA</option>
                                            <option>BSIC GHANA LTD-SPINTEX</option>
                                            <option>BSIC GHANA LTD-TAMALE</option>
                                            <option>BSIC GHANA LTD-TEMA</option>
                                        </optgroup>
                                        <optgroup label="CAL BANK">
                                            <option>CAL BANK LTD - ASAFO</option>
                                            <option>CAL BANK LTD - CIRCLE BRANCH</option>
                                            <option>CAL BANK LTD - HEAD OFFICE</option>
                                            <option>CAL BANK LTD - INDEPENDENCE AVENUE</option>
                                            <option>CAL BANK LTD - WEIJA BRANCH</option>
                                            <option>CAL BANK LTD-ACCRA</option>
                                            <option>CAL BANK LTD-COMMUNITY 25</option>
                                            <option>CAL BANK LTD-GRAPHIC ROAD</option>
                                            <option>CAL BANK LTD-KEJETIA</option>
                                            <option>CAL BANK LTD-KNUST</option>
                                            <option>CAL BANK LTD-LEGON</option>
                                            <option>CAL BANK LTD-NHYIEASO</option>
                                            <option>CAL BANK LTD-RING ROAD CENTRAL BRANCH</option>
                                            <option>CAL BANK LTD-SPINTEX ROAD</option>
                                            <option>CAL BANK LTD-SPINTEX ROAD</option>
                                            <option>CAL BANK LTD-SUAME</option>
                                            <option>CAL BANK LTD-TAKORADI</option>
                                            <option>CAL BANK LTD-TAKORADI HARBOUR</option>
                                            <option>CAL BANK LTD-TARKWA</option>
                                            <option>CAL BANK LTD-TEMA</option>
                                            <option>CAL BANK LTD-TEMA HARBOUR</option>
                                            <option>CAL BANK-DANSOMAN</option>
                                            <option>CAL-ACHIMOTA</option>
                                            <option>CAL-FIRST AFRICAN SAV AND LOANS</option>
                                            <option>CAL-MIDLAND SAV AND LOANS</option>
                                            <option>CAL-OSU</option>
                                            <option>CAL BANK EAST LEGON</option>
                                        </optgroup>
                                        <optgroup label="ECOBANK">
                                            <option>ECOBANK ASHTOWN EAST BRANCH</option>
                                            <option>ECOBANK BUI BRANCH</option>
                                            <option>ECOBANK BURMA CAMP BRANCH</option>
                                            <option>
                                                ECOBANK (GH) LTD-FIRST ALLIED SAV AND LO
                                            </option>
                                            <option>ECOBANK ASHIAMAN BRANCH</option>
                                            <option>ECOBANK BANTAMA GNTC BRANCH</option>
                                            <option>ECOBANK COLLINS STREET BRANCH</option>
                                            <option>ECOBANK COMMUNITY 1 BRANCH</option>
                                            <option>ECOBANK EAST AIRPORT BRANCH</option>
                                            <option>ECOBANK EVANDY HOSTEL BRANCH</option>
                                            <option>ECOBANK GH ABREPO KSI</option>
                                            <option>ECOBANK GH A AND C</option>
                                            <option>ECOBANK GH ABEKA LAPAZ</option>
                                            <option>ECOBANK GH ABREPO MAIN</option>
                                            <option>ECOBANK GH ACCRA MAIN</option>
                                            <option>ECOBANK GH ACCRA MALL</option>
                                            <option>ECOBANK GH AFLAO BRANCH</option>
                                            <option>ECOBANK GH ASH TOWN</option>
                                            <option>ECOBANK GH BANTAMA</option>
                                            <option>ECOBANK GH DANSOMAN</option>
                                            <option>ECOBANK GH DARKUMAN</option>
                                            <option>ECOBANK GH ELUBO</option>
                                            <option>ECOBANK GH HAATSO</option>
                                            <option>ECOBANK GH JUBILEE HSE</option>
                                            <option>ECOBANK GH KEJETIA</option>
                                            <option>ECOBANK GH KENYASE</option>
                                            <option>ECOBANK GH KISSEIMAN</option>
                                            <option>ECOBANK GH KNUST</option>
                                            <option>ECOBANK GH KOTOBABI</option>
                                            <option>ECOBANK GH KUMASI</option>
                                            <option>ECOBANK GH KUMASI ADUM</option>
                                            <option>ECOBANK GH LABONE BRANCH</option>
                                            <option>ECOBANK GH LEGON</option>
                                            <option>ECOBANK GH LONG ROOM</option>
                                            <option>ECOBANK GH LTD TAMALE</option>
                                            <option>ECOBANK GH MACC HILL</option>
                                            <option>ECOBANK GH MADINA</option>
                                            <option>ECOBANK GH MILE 7 ACHIMOTA</option>
                                            <option>ECOBANK GH MKT CLE TDI</option>
                                            <option>ECOBANK GH NEW ABIREM</option>
                                            <option>ECOBANK GH NIMA</option>
                                            <option>ECOBANK GH OSU</option>
                                            <option>ECOBANK GH RING ROAD</option>
                                            <option>ECOBANK GH SAFE BOND BRANCH</option>
                                            <option>ECOBANK GH SOUTH IND</option>
                                            <option>ECOBANK GH SPINTEX RD</option>
                                            <option>ECOBANK GH SST</option>
                                            <option>ECOBANK GH STADIUM AMAKOM</option>
                                            <option>ECOBANK GH SUNYANI</option>
                                            <option>ECOBANK GH TAFO</option>
                                            <option>ECOBANK GH TAKORADI</option>
                                            <option>ECOBANK GH TANOSO</option>
                                            <option>ECOBANK GH TARKWA</option>
                                            <option>ECOBANK GH TEMA</option>
                                            <option>ECOBANK GH TEMA COMM 6</option>
                                            <option>ECOBANK GH TEMA MALL</option>
                                            <option>ECOBANK GH TUDU</option>
                                            <option>ECOBANK GH WEIJA</option>
                                            <option>ECOBANK HIGH STREET BRANCH</option>
                                            <option>ECOBANK HOSPITAL ROAD BRANCH</option>
                                            <option>ECOBANK KANTAMANTO BRANCH</option>
                                            <option>ECOBANK KASOA BRANCH</option>
                                            <option>ECOBANK KISSEIMAN BRANCH</option>
                                            <option>ECOBANK KWABENYA BRANCH</option>
                                            <option>ECOBANK KWASHIEMAN BRANCH</option>
                                            <option>ECOBANK MADINA CENTRAL BRANCH</option>
                                            <option>ECOBANK NEW ABEKA</option>
                                            <option>ECOBANK OKOFO HOUSE BRANCH</option>
                                            <option>ECOBANK OKPONGLO BRANCH</option>
                                            <option>ECOBANK PENTAGON LEGON BRANCH</option>
                                            <option>ECOBANK REINSURANCE HOUSE BRANCH</option>
                                            <option>ECOBANK SAKUMONO BRANCH</option>
                                            <option>ECOBANK SSNIT HOUSE BRANCH</option>
                                            <option>ECOBANK ST JOHNS</option>
                                            <option>ECOBANK SUAME BRANCH</option>
                                            <option>ECOBANK TEMA MOTOR WAY ROUNDABOUT</option>
                                            <option>ECOBANK TESANO BRANCH</option>
                                            <option>ECOBANK TRUST TOWERS BRANCH</option>
                                            <option>ECOBANK(GH)LTD- FIRST NAT SAV AND LOANS</option>
                                            <option>ECOBANK(GH)LTD- PROCREDIT SAV AND LOANS</option>
                                            <option>ECOBANK-ADEHYEMAN SAV AND LOANS</option>
                                            <option>ECOBANK-BEIGE CAPITAL</option>
                                            <option>ECOBANK-ECOBANK ACCION</option>
                                            <option>ECOBANK-GOLDEN PRIDE SAV AND LOANS</option>
                                            <option>ECOBANK-NBFI ADVANS</option>
                                            <option>ECOBANK-OPPORT SAV AND LOANS</option>
                                            <option>ECOBANK-PRIVATE BANKING</option>
                                            <option>ECOBANK-WOMENS WORLD BANK</option>
                                            <option>ECOBANK ESIAMA BRANCH</option>
                                            <option>ECOBANK System Branch</option>
                                        </optgroup>
                                        <optgroup label="ENERGY BANK">
                                            <option>ENERGY BANK (GH) LTD - ADUM</option>
                                            <option>ENERGY BANK CHG</option>
                                            <option>ENERGY BANK GH LTD - ACCRA CENTRAL</option>
                                            <option>ENERGY BANK TEMA BRANCH</option>
                                        </optgroup>
                                        <optgroup label="FBN BANK GHANA">
                                            <option>FBN BANK RING ROAD CENTRAL</option>
                                            <option>FBN BANK TEMA</option>
                                            <option>FBN BANK ACCRA</option>
                                            <option>FBN BANK DANSOMAN</option>
                                            <option>FBN BANK KANESHIE</option>
                                            <option>FBN BANK KASOA</option>
                                            <option>FBN BANK SPINTEX RD</option>
                                            <option>FBN BANK SWEDRU</option>
                                            <option>FBN BANK -ACHIMOTA</option>
                                            <option>FBN BANK GHANA - TECHIMAN</option>
                                            <option>FBN BANK GHANA DOME</option>
                                            <option>FBN BANK GHANA LTD-ADUM</option>
                                            <option>FBN BANK GHANA SUAME</option>
                                            <option>FBN BANK GHANA SUAME</option>
                                            <option>FBN BANK GHANA SWANMILL</option>
                                            <option>FBN BANK GHANA -KORLE BU</option>
                                            <option>FBN BANK GHANA -SANTA MARIA</option>
                                            <option>FBN BANK GHANA -TAKORADI</option>
                                        </optgroup>
                                        <optgroup label="FIDELITY BANK">
                                            <option>FIDELITY BANK LTD-NUNGUA BRANCH</option>
                                            <option>FIDELITY BANK SUNYANI -POST BANK</option>
                                            <option>FIDELITY BANK TARKWA-BRANCH</option>
                                            <option>FIDELITY BANK (GH) LTD -TRADE FAIR LA</option>
                                            <option>FIDELITY BANK (GH) LTD- TESANO</option>
                                            <option>FIDELITY BANK - ACCRA CENTRAL POST BANK</option>
                                            <option>FIDELITY BANK - BOLGATANGA</option>
                                            <option>FIDELITY BANK - LAPAZ BRANCH</option>
                                            <option>FIDELITY BANK - Mamprobi</option>
                                            <option>FIDELITY BANK GH LTD-SINAPI ABA SAVING</option>
                                            <option>FIDELITY BANK GHANA</option>
                                            <option>FIDELITY BANK GHANA - A AND C MALL</option>
                                            <option>FIDELITY BANK GHANA - ASSIN FOSU</option>
                                            <option>FIDELITY BANK GHANA - ATONSU</option>
                                            <option>FIDELITY BANK GHANA Ahodwo</option>
                                            <option>FIDELITY BANK GHANA LTD - DZORWULU</option>
                                            <option>FIDELITY BANK GHANA LTD-ABOSSEY OKAI</option>
                                            <option>FIDELITY BANK GHANA LTD-DANSOMAN</option>
                                            <option>FIDELITY BANK GHANA LTD-HAATSO</option>
                                            <option>FIDELITY BANK GHANA LTD-HIGH STREET</option>
                                            <option>FIDELITY BANK GHANA LTD-KANTAMANTO</option>
                                            <option>FIDELITY BANK GHANA LTD-KUMASI-ADUM</option>
                                            <option>FIDELITY BANK GHANA LTD-KUMASI-SUAME</option>
                                            <option>FIDELITY BANK GHANA LTD-OKAISHIE</option>
                                            <option>FIDELITY BANK GHANA LTD-OSU</option>
                                            <option>FIDELITY BANK GHANA LTD-RIDGE TOWERS</option>
                                            <option>FIDELITY BANK GHANA LTD-RING ROAD</option>
                                            <option>FIDELITY BANK GHANA LTD-SPINTEX ROAD</option>
                                            <option>FIDELITY BANK GHANA LTD-STADIUM POST</option>
                                            <option>
                                                FIDELITY BANK GHANA LTD-TEMA COMMUNITY 2
                                            </option>
                                            <option>FIDELITY BANK GHANA LTD-TEMA SAFE BOND</option>
                                            <option>FIDELITY BANK GHANA LTD-TUDU</option>
                                            <option>FIDELITY BANK GHANA-ACTION CHAPEL</option>
                                            <option>
                                                FIDELITY BANK GHANA-KWAME NKRUMAH AVENUE
                                            </option>
                                            <option>FIDELITY BANK GHANA-TEMA-COMMUNITY ONE</option>
                                            <option>FIDELITY BANK KOKOMLEMLE BRANCH</option>
                                            <option>FIDELITY BANK LIMITED- IPS</option>
                                            <option>FIDELITY BANK LIMITED-HO</option>
                                            <option>FIDELITY BANK LTD -ASHAIMAN</option>
                                            <option>FIDELITY BANK LTD ADENTA</option>
                                            <option>FIDELITY BANK LTD-MARKET CIRCLE BRANCH</option>
                                            <option>FIDELITY BANK TAKORADI POST</option>
                                            <option>FIDELITY BANK TAKORADI POST</option>
                                            <option>FIDELITY BANK TAMAE</option>
                                            <option>FIDELITY BANK- AIRPORT BRANCH</option>
                                            <option>FIDELITY BANK- SANTASI ROUNDABOUT</option>
                                            <option>FIDELITY BANK- TUDU</option>
                                            <option>FIDELITY BANK-ABEKA</option>
                                            <option>FIDELITY BANK-ADUM PZ</option>
                                            <option>FIDELITY BANK-ADUM SAGOE LANE</option>
                                            <option>FIDELITY BANK-AIRPORT PC</option>
                                            <option>FIDELITY BANK-ASHAIMAN MARKET</option>
                                            <option>FIDELITY BANK-BEREKUM</option>
                                            <option>FIDELITY BANK-CAPE COAST</option>
                                            <option>FIDELITY BANK-DANSOMAN MAIN</option>
                                            <option>FIDELITY BANK-DOME</option>
                                            <option>FIDELITY BANK-HOHOE</option>
                                            <option>FIDELITY BANK-KANESHIE MAIN BRANCH</option>
                                            <option>FIDELITY BANK-KANESHIE MARKET</option>
                                            <option>FIDELITY BANK-KASOA</option>
                                            <option>FIDELITY BANK-KUMASI ADUM-POST BANK</option>
                                            <option>FIDELITY BANK-MAAMOBI</option>
                                            <option>FIDELITY BANK-MADINA</option>
                                            <option>FIDELITY BANK-MADINA MARKET BRANCH</option>
                                            <option>FIDELITY BANK-NKAWKAW BRANCH</option>
                                            <option>FIDELITY BANK-NUNGUA BRIGADE</option>
                                            <option>FIDELITY BANK-OSU DANQUAH CIRCLE</option>
                                            <option>FIDELITY BANK-SUAME MAIN</option>
                                            <option>FIDELITY BANK-SUNYANI</option>
                                            <option>FIDELITY BANK-TAKORADI EFFIEKUMA</option>
                                            <option>FIDELITY BANK-TAKORADI MAIN</option>
                                            <option>FIDELITY BANK-TAKORADI MARKET CIRCLE</option>
                                            <option>FIDELITY BANK-TARKWA MAIN</option>
                                            <option>FIDELITY BANK-TECHIMAN</option>
                                            <option>FIDELITY BANK-TEMA COMM 1 MAIN BRANCH</option>
                                        </optgroup>
                                        <optgroup label="FIRST ATLANTIC MERCHANT">
                                            <option>FIRST ATLANTIC - WWB S&amp;L</option>
                                            <option>FIRST ATLANTIC BANK AIRPORT BRANCH</option>
                                            <option>
                                                FIRST ATLANTIC BANK LTD - NORTH IND AREA
                                            </option>
                                            <option>FIRST ATLANTIC MERCHANT BANK LTD-ACCRA</option>
                                            <option>
                                                FIRST ATLANTIC MERCHANT BANK LTD-KANTAMANTO AGENCY
                                            </option>
                                            <option>FIRST ATLANTIC MERCHANT BANK LTD-KUMASI</option>
                                            <option>FIRST ATLANTIC MERCHANT BANK LTD-MAKOLA</option>
                                            <option>FIRST ATLANTIC MERCHANT BANK LTD-SUAME</option>
                                            <option>FIRST ATLANTIC MERCHANT BANK LTD-TEMA</option>
                                            <option>FIRST ATLANTIC MERCHANT BANK-RIDGE</option>
                                            <option>FIRST ATLANTIC MERCHANT BANK-SPINTEX RD</option>
                                        </optgroup>
                                        <optgroup label="FIRST CAPITAL PLUS  BANK">
                                            <option>FIRST CAPIAL PLUS BANK-HEAD OFFICE</option>
                                            <option>FIRST CAPITAL PLUS BANK ASHIAMAN</option>
                                            <option>FIRST CAPITAL PLUS BANK DANSOMAN</option>
                                            <option>FIRST CAPITAL PLUS BANK NEW TOWN</option>
                                            <option>FIRST CAPITAL PLUS BANK-ABOSSEY OKAI</option>
                                            <option>FIRST CAPITAL PLUS BANK-CAPE COAST</option>
                                            <option>FIRST CAPITAL PLUS BANK-CUC MIOTSO</option>
                                            <option>FIRST CAPITAL PLUS BANK-FOREIGN</option>
                                            <option>FIRST CAPITAL PLUS BANK-KASOA</option>
                                            <option>FIRST CAPITAL PLUS BANK-KOFORIDUA</option>
                                            <option>FIRST CAPITAL PLUS BANK-KUMASI</option>
                                            <option>FIRST CAPITAL PLUS BANK-MAKOLA</option>
                                            <option>FIRST CAPITAL PLUS BANK-OSU</option>
                                            <option>FIRST CAPITAL PLUS BANK-SPINTEX</option>
                                            <option>FIRST CAPITAL PLUS BANK-TAKORADI</option>
                                            <option>FIRST CAPITAL PLUS BANK-TAMALE</option>
                                            <option>FIRST CAPITAL PLUS BANK-TECHIMAN</option>
                                            <option>FIRST CAPITAL PLUS BANK-TEMA</option>
                                            <option>FIRST CAPITAL PLUS BANK-TESANO</option>
                                            <option>FIRST CAPITAL PLUS BANK-UEW BRANCH</option>
                                        </optgroup>
                                        <optgroup label="FIRST NATIONAL BANK">
                                            <option>FIRST NATIONAL BANK - ACCRA</option>
                                            <option>FIRST NATIONAL BANK - ACCRA MALL</option>
                                            <option>FIRST NATIONAL BANK - ACHIMOTA MALL</option>
                                            <option>
                                                FIRST NATIONAL BANK - JUNCTION SHOP CENT
                                            </option>
                                            <option>FIRST NATIONAL BANK - WEST HILLS MALL</option>
                                        </optgroup>
                                        <optgroup label="GCB BANK LTD">
                                            <option>GCB 18APRIL HARPER ROAD-KUMASI</option>
                                            <option>GCB BANK LTD-AMASAMAN</option>
                                            <option>GCB INTERNATIONAL TRADE FINANCE</option>
                                            <option>GCB L BANK SAFEBOND (JUBILEE) TEMA</option>
                                            <option>GCB - PEKI BRANCH</option>
                                            <option>GHANA COMM UNIVERSITY OF CAPE COAST</option>
                                            <option>GHANA COMM - GLOBAL TRANSFER SERVICES</option>
                                            <option>
                                                GHANA COMM BANK CENTRAL PROCESSING CENTR
                                            </option>
                                            <option>GHANA COMMERCIAL ACCRA NORTH CIRCLE</option>
                                            <option>GHANA COMMERCIAL KWAME NKRUMAH CIRCLE</option>
                                            <option>GHANA COMMERCIAL TAKORADI HARBOUR</option>
                                            <option>GHANA COMMERCIAL TEMA FISHING HARBOUR</option>
                                            <option>GHANA COMMERCIAL - DOME KWABENYA</option>
                                            <option>GHANA COMMERCIAL -TEMA INDUSTRIAL AREA</option>
                                            <option>GHANA COMMERCIAL 31ST DECEMBER MARKET</option>
                                            <option>GHANA COMMERCIAL BANK NUNGUA</option>
                                            <option>GHANA COMMERCIAL BANK ABEKA LAPAZ</option>
                                            <option>
                                                GHANA COMMERCIAL BANK ABEKA LAPAZ BRANCH
                                            </option>
                                            <option>GHANA COMMERCIAL BANK ABLENKPE</option>
                                            <option>GHANA COMMERCIAL BANK ABOR</option>
                                            <option>GHANA COMMERCIAL BANK ABURA DUNKWA</option>
                                            <option>GHANA COMMERCIAL BANK ABURI</option>
                                            <option>GHANA COMMERCIAL BANK ACCRA NEW TOWN</option>
                                            <option>
                                                GHANA COMMERCIAL BANK ACCRA NORTH CIRCLE
                                            </option>
                                            <option>GHANA COMMERCIAL BANK ADA FOAH</option>
                                            <option>GHANA COMMERCIAL BANK ADENTA MARKET</option>
                                            <option>GHANA COMMERCIAL BANK AFLAO</option>
                                            <option>GHANA COMMERCIAL BANK AGOGO</option>
                                            <option>GHANA COMMERCIAL BANK AGONA SWEDRU</option>
                                            <option>GHANA COMMERCIAL BANK AGONAASHANTI</option>
                                            <option>GHANA COMMERCIAL BANK AHINSANKUMASI</option>
                                            <option>GHANA COMMERCIAL BANK AKATSI</option>
                                            <option>GHANA COMMERCIAL BANK AKIM ODA</option>
                                            <option>GHANA COMMERCIAL BANK AKOSOMBO</option>
                                            <option>GHANA COMMERCIAL BANK AKROPONGAKWAPIM</option>
                                            <option>GHANA COMMERCIAL BANK AKUMADAN</option>
                                            <option>GHANA COMMERCIAL BANK ASAFO MARKET</option>
                                            <option>GHANA COMMERCIAL BANK ASAMANKESE</option>
                                            <option>GHANA COMMERCIAL BANK ASHIAMAN</option>
                                            <option>GHANA COMMERCIAL BANK ASSIN FOSU</option>
                                            <option>GHANA COMMERCIAL BANK AXIM</option>
                                            <option>GHANA COMMERCIAL BANK BANTAMA</option>
                                            <option>GHANA COMMERCIAL BANK BAWKU</option>
                                            <option>GHANA COMMERCIAL BANK BECHEM</option>
                                            <option>GHANA COMMERCIAL BANK BEKWAI</option>
                                            <option>GHANA COMMERCIAL BANK BEREKUM</option>
                                            <option>GHANA COMMERCIAL BANK BIMBILA</option>
                                            <option>GHANA COMMERCIAL BANK BOGOSO</option>
                                            <option>GHANA COMMERCIAL BANK BOLE</option>
                                            <option>GHANA COMMERCIAL BANK BOLE</option>
                                            <option>GHANA COMMERCIAL BANK BOLGATANGA</option>
                                            <option>GHANA COMMERCIAL BANK BOUNDARY ROAD</option>
                                            <option>GHANA COMMERCIAL BANK BREMAN ASIKUMA</option>
                                            <option>GHANA COMMERCIAL BANK CAPE COAST MAIN</option>
                                            <option>GHANA COMMERCIAL BANK CLEARING</option>
                                            <option>GHANA COMMERCIAL BANK DADIESO</option>
                                            <option>GHANA COMMERCIAL BANK DAMBAI</option>
                                            <option>GHANA COMMERCIAL BANK DAMBAI</option>
                                            <option>GHANA COMMERCIAL BANK DAMONGO</option>
                                            <option>GHANA COMMERCIAL BANK DERBY AVENUE</option>
                                            <option>GHANA COMMERCIAL BANK DOME (KWABENYA)</option>
                                            <option>GHANA COMMERCIAL BANK DONKOKROM</option>
                                            <option>GHANA COMMERCIAL BANK DONKOKROM</option>
                                            <option>GHANA COMMERCIAL BANK DONKOKROM</option>
                                            <option>GHANA COMMERCIAL BANK DORMAA AHENKRO</option>
                                            <option>GHANA COMMERCIAL BANK DUAYAWNKWANTA</option>
                                            <option>GHANA COMMERCIAL BANK DUNKWA ON OFFIN</option>
                                            <option>GHANA COMMERCIAL BANK DZODZE</option>
                                            <option>GHANA COMMERCIAL BANK EFFIDUASEASHANTI</option>
                                            <option>GHANA COMMERCIAL BANK EJISU</option>
                                            <option>GHANA COMMERCIAL BANK EJURA</option>
                                            <option>GHANA COMMERCIAL BANK ELMINA</option>
                                            <option>GHANA COMMERCIAL BANK ELUBU</option>
                                            <option>GHANA COMMERCIAL BANK ENCHI</option>
                                            <option>GHANA COMMERCIAL BANK GOASO</option>
                                            <option>GHANA COMMERCIAL BANK HALFASSINI</option>
                                            <option>GHANA COMMERCIAL BANK HARPER RD KUMASI</option>
                                            <option>GHANA COMMERCIAL BANK HEAD OFFICE</option>
                                            <option>GHANA COMMERCIAL BANK HO</option>
                                            <option>GHANA COMMERCIAL BANK HO MARKET</option>
                                            <option>GHANA COMMERCIAL BANK HO POLYTECHNIC</option>
                                            <option>GHANA COMMERCIAL BANK HOHOE</option>
                                            <option>GHANA COMMERCIAL BANK HOHOE</option>
                                            <option>GHANA COMMERCIAL BANK HWIDIEM</option>
                                            <option>GHANA COMMERCIAL BANK JANSIKAN</option>
                                            <option>GHANA COMMERCIAL BANK JAPEKROM</option>
                                            <option>GHANA COMMERCIAL BANK JASIKAN</option>
                                            <option>GHANA COMMERCIAL BANK JUASO</option>
                                            <option>GHANA COMMERCIAL BANK JUBILEE HOUSE</option>
                                            <option>GHANA COMMERCIAL BANK KADE</option>
                                            <option>GHANA COMMERCIAL BANK KADJEBI</option>
                                            <option>GHANA COMMERCIAL BANK KADJEBI</option>
                                            <option>GHANA COMMERCIAL BANK KANESHIE MARKET</option>
                                            <option>GHANA COMMERCIAL BANK KASOA</option>
                                            <option>GHANA COMMERCIAL BANK KASOA</option>
                                            <option>GHANA COMMERCIAL BANK KASOA MAIN</option>
                                            <option>GHANA COMMERCIAL BANK KEJETIA</option>
                                            <option>GHANA COMMERCIAL BANK KETA</option>
                                            <option>GHANA COMMERCIAL BANK KETA</option>
                                            <option>GHANA COMMERCIAL BANK KETEKRACHI</option>
                                            <option>GHANA COMMERCIAL BANK KETEKRACHI</option>
                                            <option>GHANA COMMERCIAL BANK KINTAMPO</option>
                                            <option>GHANA COMMERCIAL BANK KISSEMAN</option>
                                            <option>GHANA COMMERCIAL BANK KNUST KUMASI</option>
                                            <option>GHANA COMMERCIAL BANK KOFORIDUA</option>
                                            <option>GHANA COMMERCIAL BANK KONONGO</option>
                                            <option>GHANA COMMERCIAL BANK KPANDO</option>
                                            <option>GHANA COMMERCIAL BANK KPANDO</option>
                                            <option>GHANA COMMERCIAL BANK KUMASI MAIN</option>
                                            <option>GHANA COMMERCIAL BANK LAWRA</option>
                                            <option>GHANA COMMERCIAL BANK LIBERTY HOUSE</option>
                                            <option>GHANA COMMERCIAL BANK LTD KIBI</option>
                                            <option>GHANA COMMERCIAL BANK LTD LAWRA</option>
                                            <option>GHANA COMMERCIAL BANK LTD TUMU</option>
                                            <option>GHANA COMMERCIAL BANK LTD - DANSOMAN</option>
                                            <option>GHANA COMMERCIAL BANK LTD - NUNGUA</option>
                                            <option>GHANA COMMERCIAL BANK LTD ABURI</option>
                                            <option>GHANA COMMERCIAL BANK LTD ADA FOAH</option>
                                            <option>GHANA COMMERCIAL BANK LTD AKUSE</option>
                                            <option>GHANA COMMERCIAL BANK LTD ANYINAM</option>
                                            <option>GHANA COMMERCIAL BANK LTD- SUHUM</option>
                                            <option>GHANA COMMERCIAL BANK LTD-BURMA CAMP</option>
                                            <option>GHANA COMMERCIAL BANK LTD-HIGH STREET</option>
                                            <option>GHANA COMMERCIAL BANK LTD-HOHOE</option>
                                            <option>GHANA COMMERCIAL BANK LTD-KORLEBU</option>
                                            <option>GHANA COMMERCIAL BANK LTD-LEGON</option>
                                            <option>GHANA COMMERCIAL BANK LTD-MINISTRIES</option>
                                            <option>GHANA COMMERCIAL BANK LTD-TEMA MAIN</option>
                                            <option>GHANA COMMERCIAL BANK LTD-TEMA MARKET</option>
                                            <option>GHANA COMMERCIAL BANK MADINA</option>
                                            <option>GHANA COMMERCIAL BANK MAMPONGAKWAPIM</option>
                                            <option>GHANA COMMERCIAL BANK MAMPONGASHANTI</option>
                                            <option>GHANA COMMERCIAL BANK MANKESSIM</option>
                                            <option>GHANA COMMERCIAL BANK MIM</option>
                                            <option>GHANA COMMERCIAL BANK MPRAESO</option>
                                            <option>GHANA COMMERCIAL BANK NAVRONGO</option>
                                            <option>GHANA COMMERCIAL BANK NEW EDUBIASE</option>
                                            <option>GHANA COMMERCIAL BANK NEW OFFINSO</option>
                                            <option>GHANA COMMERCIAL BANK NEW TAFO</option>
                                            <option>GHANA COMMERCIAL BANK NEW TAFO</option>
                                            <option>GHANA COMMERCIAL BANK NIMA BRANCH</option>
                                            <option>GHANA COMMERCIAL BANK NIMA BRANCH</option>
                                            <option>GHANA COMMERCIAL BANK NKAWIE</option>
                                            <option>GHANA COMMERCIAL BANK NKAWKAW</option>
                                            <option>GHANA COMMERCIAL BANK NKORANZA</option>
                                            <option>GHANA COMMERCIAL BANK NKWANTA</option>
                                            <option>GHANA COMMERCIAL BANK NKWANTA</option>
                                            <option>GHANA COMMERCIAL BANK NSAWAM</option>
                                            <option>GHANA COMMERCIAL BANK OBUASI</option>
                                            <option>GHANA COMMERCIAL BANK OSU</option>
                                            <option>GHANA COMMERCIAL BANK PEKI</option>
                                            <option>GHANA COMMERCIAL BANK PRESTEA</option>
                                            <option>GHANA COMMERCIAL BANK PRESTEA</option>
                                            <option>GHANA COMMERCIAL BANK REPUBLIC HOUSE</option>
                                            <option>GHANA COMMERCIAL BANK RING ROAD WEST</option>
                                            <option>GHANA COMMERCIAL BANK SALAGA</option>
                                            <option>GHANA COMMERCIAL BANK SALTPOND</option>
                                            <option>GHANA COMMERCIAL BANK SAMPA</option>
                                            <option>GHANA COMMERCIAL BANK SAMREBOI</option>
                                            <option>GHANA COMMERCIAL BANK SANKORE</option>
                                            <option>GHANA COMMERCIAL BANK SEFWI WIASO</option>
                                            <option>GHANA COMMERCIAL BANK SEFWI WIAWSO</option>
                                            <option>GHANA COMMERCIAL BANK SEKONDI</option>
                                            <option>GHANA COMMERCIAL BANK SOGAKOPE</option>
                                            <option>GHANA COMMERCIAL BANK SOGAKOPE</option>
                                            <option>GHANA COMMERCIAL BANK SOMANYA</option>
                                            <option>GHANA COMMERCIAL BANK SPINTEX</option>
                                            <option>GHANA COMMERCIAL BANK SUNYANI</option>
                                            <option>GHANA COMMERCIAL BANK SUNYANI MARKET</option>
                                            <option>GHANA COMMERCIAL BANK TAKORADI MAIN</option>
                                            <option>
                                                GHANA COMMERCIAL BANK TAMALE HOSPITAL ROAD
                                            </option>
                                            <option>GHANA COMMERCIAL BANK TAMALE MAIN</option>
                                            <option>GHANA COMMERCIAL BANK TAMALE MARKET</option>
                                            <option>GHANA COMMERCIAL BANK TANTRA HILL</option>
                                            <option>GHANA COMMERCIAL BANK TARKWA</option>
                                            <option>GHANA COMMERCIAL BANK TECH JUNCTION</option>
                                            <option>GHANA COMMERCIAL BANK TECHIMAN</option>
                                            <option>GHANA COMMERCIAL BANK TECHIMAN MARKET</option>
                                            <option>GHANA COMMERCIAL BANK TEPA</option>
                                            <option>GHANA COMMERCIAL BANK TRADE FAIR SITE</option>
                                            <option>GHANA COMMERCIAL BANK TUMU</option>
                                            <option>GHANA COMMERCIAL BANK TWIFO PRASO</option>
                                            <option>GHANA COMMERCIAL BANK WA</option>
                                            <option>GHANA COMMERCIAL BANK WA</option>
                                            <option>GHANA COMMERCIAL BANK WALEWALE</option>
                                            <option>GHANA COMMERCIAL BANK WENCHI</option>
                                            <option>GHANA COMMERCIAL BANK WINNEBA</option>
                                            <option>GHANA COMMERCIAL BANK YEJI</option>
                                            <option>GHANA COMMERCIAL BANK YENDI</option>
                                            <option>GHANA COMMERCIAL KANESHIE IND AREA</option>
                                            <option>GHANA COMMERCIAL TAKORADI MAKET CIRCLE</option>
                                            <option>GHANA COMMERCIAL TETTEH QUARSHIE CIRCLE</option>
                                            <option>GHANA COMMERCIAL- ABLENKPE</option>
                                            <option>GHANA COMMERCIAL-DANSOMAN</option>
                                        </optgroup>
                                        <optgroup label="GN BANK LTD">
                                            <option>GN BANK - ELIMINA</option>
                                            <option>GN BANK NAVRONGO</option>
                                            <option>GN BANK - ABOSSEY OKAI</option>
                                            <option>GN BANK - ADENTAN</option>
                                            <option>GN BANK - AGBOGBLOSHI</option>
                                            <option>GN BANK - AIYANASE</option>
                                            <option>GN BANK - ASAMANKESE</option>
                                            <option>GN BANK - BIMBILLA</option>
                                            <option>GN BANK - DAMANG</option>
                                            <option>GN BANK - DOME</option>
                                            <option>GN BANK - DONKORKROM</option>
                                            <option>GN BANK - DORMA AAHENKRO</option>
                                            <option>GN BANK - ESIAMA</option>
                                            <option>GN BANK - HALF ASSINI</option>
                                            <option>GN BANK - HO</option>
                                            <option>GN BANK - JUKWA</option>
                                            <option>GN BANK - KINTAMPO</option>
                                            <option>GN BANK - MPRAESO</option>
                                            <option>GN BANK - NANDOM</option>
                                            <option>GN BANK - NSAWAM</option>
                                            <option>GN BANK - PRESTEA</option>
                                            <option>GN BANK - SAMPA</option>
                                            <option>GN BANK - SUNYANI CENRAL</option>
                                            <option>GN BANK - TAKORADI</option>
                                            <option>GN BANK - TAMALE LAMESHEGU</option>
                                            <option>GN BANK - TARKWA</option>
                                            <option>GN BANK - TOLON</option>
                                            <option>GN BANK - WA</option>
                                            <option>GN BANK - WIDANA</option>
                                            <option>GN BANK - ZEBILLA</option>
                                            <option>GN BANK -FOREIGN OPERATIONS</option>
                                            <option>GN BANK -YENDI</option>
                                            <option>GN BANK AGONA NKWANTA</option>
                                            <option>GN BANK AGONA SWEDRU</option>
                                            <option>GN BANK AKWATIA</option>
                                            <option>GN BANK APAM</option>
                                            <option>GN BANK APLAKU</option>
                                            <option>GN BANK ASIKUM</option>
                                            <option>GN BANK AWUTU</option>
                                            <option>GN BANK BANK</option>
                                            <option>GN BANK BAWKU</option>
                                            <option>GN BANK BEREKUM</option>
                                            <option>GN BANK BOLE</option>
                                            <option>GN BANK BOLGATANGA</option>
                                            <option>GN BANK CHAMBA</option>
                                            <option>GN BANK DABOASE</option>
                                            <option>GN BANK ELUBO</option>
                                            <option>GN BANK GURU</option>
                                            <option>GN BANK HAMILE</option>
                                            <option>GN BANK HOHOE</option>
                                            <option>GN BANK JASIKAN</option>
                                            <option>GN BANK JIRAPA</option>
                                            <option>GN BANK KASOA</option>
                                            <option>GN BANK KENYASE</option>
                                            <option>GN BANK KOFORIDUA</option>
                                            <option>GN BANK LAWRA</option>
                                            <option>GN BANK LTD</option>
                                            <option>GN BANK LTD - AKIM ODA</option>
                                            <option>GN BANK LTD - ASHIAMAN</option>
                                            <option>GN BANK LTD - ASYLUM</option>
                                            <option>GN BANK LTD - CHAPEL SQUARE</option>
                                            <option>GN BANK LTD - CHORKOR</option>
                                            <option>GN BANK LTD - KPONG</option>
                                            <option>GN BANK LTD - MFANTSIPIM</option>
                                            <option>GN BANK LTD - NIMA</option>
                                            <option>GN BANK LTD - NORTH RIDGE</option>
                                            <option>GN BANK LTD - SAKUMONO</option>
                                            <option>GN BANK LTD - SEGE</option>
                                            <option>GN BANK LTD - TAMALE</option>
                                            <option>GN BANK LTD - TEMA</option>
                                            <option>GN BANK LTD- AKWETEYMAN</option>
                                            <option>GN BANK LTD- MALLAM</option>
                                            <option>GN BANK LTD- TECHIMAN</option>
                                            <option>GN BANK MAKOLA</option>
                                            <option>GN BANK MANKESSIM</option>
                                            <option>GN BANK MELCOM</option>
                                            <option>GN BANK NKORANZA</option>
                                            <option>GN BANK NUNGUA</option>
                                            <option>GN BANK OKPONGLO</option>
                                            <option>GN BANK POTSIN</option>
                                            <option>GN BANK PUSIGA</option>
                                            <option>GN BANK RING ROAD</option>
                                            <option>GN BANK SABOBA</option>
                                            <option>GN BANK SAEBU</option>
                                            <option>GN BANK SAVELUGU</option>
                                            <option>GN BANK SAWLA</option>
                                            <option>GN BANK SEKONDI</option>
                                            <option>GN BANK TANOKROM</option>
                                            <option>GN BANK TARKWA</option>
                                            <option>GN BANK TUMU</option>
                                            <option>GN BANK TUOBODOM</option>
                                            <option>GN BANK TWIFO PRASO</option>
                                            <option>GN BANK WALEWALE</option>
                                            <option>GN BANK WECHIAU</option>
                                            <option>GN BANK WENCHI</option>
                                            <option>GN BANK-ADUM</option>
                                            <option>GN BANK-AWASO</option>
                                            <option>GN BANK-BIA DEBISO</option>
                                            <option>GN BANK-DUNKWA</option>
                                            <option>GN BANK-DZEMENI</option>
                                            <option>GN BANK-KONONGO</option>
                                            <option>GN BANK-KWADASO</option>
                                            <option>GN BANK-MAMPONG</option>
                                            <option>GN BANK-NEW ADUBIASE</option>
                                            <option>GN BANK-NKAWKAW</option>
                                            <option>GN BANK-OBUASI</option>
                                            <option>GN BANK-ROMAN HILL</option>
                                            <option>GN BANK-SEFWI BEKWAI</option>
                                            <option>GN BANK-SUNYANI NIMPONG</option>
                                            <option>THE GN BANK LTD - WULENSI</option>
                                        </optgroup>
                                        <optgroup label="GUARANTY TRUST BANK">
                                            <option>GUARANTY TRUST North Industrial Area</option>
                                            <option>GT BANK - DOME BRNACH</option>
                                            <option>GT BANK - EAST LEGON</option>
                                            <option>GT BANK - TUDU BRANCH</option>
                                            <option>GT BANK ACHIMOTA</option>
                                            <option>GT Bank Ashiaman Branch</option>
                                            <option>GT BANK GRAPHIC ROAD</option>
                                            <option>GT BANK LABONE</option>
                                            <option>GT BANK LTD</option>
                                            <option>GT BANK MADINA</option>
                                            <option>GT BANK TEMA COMM 6</option>
                                            <option>Guaranty Trust (Gh) Limited Takoradi</option>
                                            <option>GUARANTY TRUST (GH) LTD- TAMALE</option>
                                            <option>GUARANTY TRUST (GH) LTD-AFLAO</option>
                                            <option>GUARANTY TRUST (GH) LTD-AIRPORT</option>
                                            <option>GUARANTY TRUST (GH) LTD-HEAD OFFICE</option>
                                            <option>
                                                GUARANTY TRUST (GH) LTD-KUMASI-LAKE ROAD
                                            </option>
                                            <option>GUARANTY TRUST (GH) LTD-LAPAZ</option>
                                            <option>GUARANTY TRUST (GH) LTD-OPERA</option>
                                            <option>GUARANTY TRUST (GH) LTD-OPERA</option>
                                            <option>GUARANTY TRUST (GH) LTD-OSU</option>
                                            <option>GUARANTY TRUST (GH) LTD-SPINTEX ROAD</option>
                                            <option>GUARANTY TRUST (GH) LTD-TARKWA</option>
                                            <option>GUARANTY TRUST (GH) LTD-TEMA</option>
                                            <option>GUARANTY TRUST - RING ROAD BRANCH</option>
                                            <option>GUARANTY TRUST BANK</option>
                                            <option>GUARANTY TRUST BANK</option>
                                            <option>GUARANTY TRUST BANK - BAATSONA BRANCH</option>
                                            <option>GUARANTY TRUST BANK Tema Main Harbour</option>
                                            <option>GUARANTY TRUST BANK Techiman</option>
                                        </optgroup>
                                        <optgroup label="HFC BANK">
                                            <option>HFC BANK (GH) LTD - CAPE COAST</option>
                                            <option>HFC BANK (GH) LTD- AGBOGBLOSHIE</option>
                                            <option>HFC BANK (GH) LTD- BAATSONA</option>
                                            <option>HFC BANK (GH) LTD- WINNEBA</option>
                                            <option>HFC BANK (GH) LTD-ABOSEY OKAI</option>
                                            <option>HFC BANK (GH) LTD-ACCRA CENTRAL</option>
                                            <option>HFC BANK (GH) LTD-ADUM</option>
                                            <option>HFC BANK (GH) LTD-ASHIAMAN</option>
                                            <option>HFC BANK (GH) LTD-EBANKESE</option>
                                            <option>HFC BANK (GH) LTD-KNUST</option>
                                            <option>HFC BANK (GH) LTD-KOFORIDUA</option>
                                            <option>HFC BANK (GH) LTD-KUMASI</option>
                                            <option>HFC BANK (GH) LTD-KUMASI MAGAZINE</option>
                                            <option>HFC BANK (GH) LTD-LEGON</option>
                                            <option>HFC BANK (GH) LTD-POST OFFICE SQUARE</option>
                                            <option>HFC BANK (GH) LTD-PRIVATE BANKING</option>
                                            <option>HFC BANK (GH) LTD-RIDGE</option>
                                            <option>HFC BANK (GH) LTD-TAKORADI</option>
                                            <option>HFC BANK (GH) LTD-TAMALE</option>
                                            <option>HFC BANK (GH) LTD-TECHIMAN</option>
                                            <option>HFC BANK (GH) LTD-TEMA</option>
                                            <option>HFC BANK (GH) LTD-TUDU</option>
                                            <option>HFC BANK GHANA LTD-SWEDRU</option>
                                            <option>HFC BANK(GH) LTD-SEFWI-WIASO</option>
                                            <option>HFC BANK(GH)LTD -ESSAM</option>
                                            <option>HFC BANK(GH)LTD-ASAMANKESE</option>
                                            <option>HFC-ACCRA NEW TOWN</option>
                                            <option>HFC-ACHIMOTA</option>
                                            <option>HFC-ADOBOKROM</option>
                                            <option>HFC-AKONTOMBRA</option>
                                            <option>HFC-ASANHRAGWA</option>
                                            <option>HFC-ASEMPENAYE</option>
                                            <option>HFC-ASOKWA</option>
                                            <option>HFC-DANSOMAN</option>
                                            <option>HFC-GOASO</option>
                                            <option>HFC-JUABESO</option>
                                            <option>HFC-KASOA</option>
                                            <option>HFC-MADINA</option>
                                            <option>HFC-SEFWI BEKWAI</option>
                                        </optgroup>
                                        <optgroup label="INTERCONTINENTAL BANK LTD">
                                            <option>
                                                INTERCONTINENTAL BANK -NORTH INDUSTIAL AREA
                                            </option>
                                            <option>INTERCONTINENTAL BANK -ABAKA LAPAZ</option>
                                            <option>INTERCONTINENTAL BANK -AGBOGBLOSHIE</option>
                                            <option>
                                                INTERCONTINENTAL BANK -KANESHIE POST OFF
                                            </option>
                                            <option>
                                                INTERCONTINENTAL BANK -RING ROAD CENTRAL
                                            </option>
                                            <option>INTERCONTINENTAL BANK GHANA -ADUM</option>
                                            <option>INTERCONTINENTAL BANK GHANA -ASHAIMAN</option>
                                            <option>
                                                INTERCONTINENTAL BANK GHANA -CASTLE ROAD
                                            </option>
                                            <option>INTERCONTINENTAL BANK GHANA -IPS</option>
                                            <option>INTERCONTINENTAL BANK GHANA -KANESHIE</option>
                                            <option>INTERCONTINENTAL BANK GHANA -MADINA</option>
                                            <option>INTERCONTINENTAL BANK GHANA -OKAISHIE</option>
                                            <option>INTERCONTINENTAL BANK GHANA -OSU</option>
                                            <option>INTERCONTINENTAL BANK GHANA -SUAME</option>
                                            <option>INTERCONTINENTAL BANK GHANA -TAKORADI</option>
                                            <option>INTERCONTINENTAL BANK GHANA -TAMALE</option>
                                            <option>INTERCONTINENTAL BANK GHANA -TARKWA</option>
                                            <option>INTERCONTINENTAL BANK GHANA -TECHIMAN</option>
                                            <option>INTERCONTINENTAL BANK GHANA -TEMA</option>
                                            <option>INTERCONTINENTAL BANK GHANA- ACHIMOTA</option>
                                            <option>
                                                INTERCONTINENTAL BANK GHANA- HEAD OFFICE
                                            </option>
                                            <option>INTERCONTINENTAL BANK GHANA-KANTAMANTO</option>
                                            <option>INTERCONTINENTAL BANK- TEMA COMMUNITY 1</option>
                                        </optgroup>
                                        <optgroup label="MTN Mobile">
                                            <option>MTN Mobile</option>
                                        </optgroup>
                                        <optgroup label="NATIONAL INVESTMENT BANK">
                                            <option>FIRST GHANA BUILDING SOCIETY(NIB)</option>
                                            <option>FIRST GHANA BUILDING SOCIETY(NIB) ACCRA</option>
                                            <option>
                                                FIRST GHANA BUILDING SOCIETY(NIB) KOFORI
                                            </option>
                                            <option>FIRST GHANA BUILDING SOCIETY(NIB) SUAME</option>
                                            <option>
                                                FIRST GHANA BUILDING SOCIETY(NIB) TAKORA
                                            </option>
                                            <option>FIRST GHANA BUILDING SOCIETY(NIB) TEMA</option>
                                            <option>FIRST GHANA BUILDING SOCIETY-AMOKOM KSI</option>
                                            <option>FIRST GHANA BUILDING SOCIETY-NIB HOHOE</option>
                                            <option>
                                                FIRST GHANA BUILDING SOCIETY-NIB SUNYANI
                                            </option>
                                            <option>
                                                FIRST GHANA BUILDING SOCIETY-NIB TAKORAD
                                            </option>
                                            <option>NATIOAL INVESTMENT -TEMA HABOUR AREA</option>
                                            <option>NATIOAL INVESTMENT BANK-ABEKA</option>
                                            <option>NATIOAL INVESTMENT BANK-ACCRA MAIN</option>
                                            <option>NATIOAL INVESTMENT BANK-ADENTA</option>
                                            <option>NATIOAL INVESTMENT BANK-AKIM ODA</option>
                                            <option>NATIOAL INVESTMENT BANK-BOLGATANGA</option>
                                            <option>NATIOAL INVESTMENT BANK-CAPE COAST</option>
                                            <option>NATIOAL INVESTMENT BANK-CLEARING UNIT</option>
                                            <option>NATIOAL INVESTMENT BANK-FOREIGN</option>
                                            <option>NATIOAL INVESTMENT BANK-HO</option>
                                            <option>NATIOAL INVESTMENT BANK-KOFORIDUA</option>
                                            <option>NATIOAL INVESTMENT BANK-KUMASI</option>
                                            <option>NATIOAL INVESTMENT BANK-KUMASI CENTRAL</option>
                                            <option>NATIOAL INVESTMENT BANK-LOTTERIES</option>
                                            <option>NATIOAL INVESTMENT BANK-OBUASI</option>
                                            <option>NATIOAL INVESTMENT BANK-OSU</option>
                                            <option>NATIOAL INVESTMENT BANK-SPINTEX</option>
                                            <option>NATIOAL INVESTMENT BANK-SUNYANI</option>
                                            <option>NATIOAL INVESTMENT BANK-TAKORADI</option>
                                            <option>NATIOAL INVESTMENT BANK-TAMALE</option>
                                            <option>NATIOAL INVESTMENT BANK-TECHIMAN</option>
                                            <option>NATIOAL INVESTMENT BANK-TEMA</option>
                                            <option>NATIOAL INVESTMENT BANK-WA</option>
                                            <option>NATIOAL INVESTMENT BANK-WINNEBA ROAD</option>
                                            <option>NATIONAL INVESTEMENT BANK</option>
                                            <option>
                                                NATIONAL INVESTEMENT BANK - DUNKWA ON-OF
                                            </option>
                                            <option>NATIONAL INVESTMENT -DUNKWA-ON-OFFIN</option>
                                            <option>NATIONAL INVESTMENT -NORTH INDL AREA</option>
                                            <option>NATIONAL INVESTMENT -TEMA COMMUNITY 9</option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - ASHAIMAN BRAN
                                            </option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - ASOKWA BRANCH
                                            </option>
                                            <option>NATIONAL INVESTMENT BANK - KUMASI CITY</option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - MADINA BRANCH
                                            </option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - NKWANTA BRANC
                                            </option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - PARLIAMENT HO
                                            </option>
                                            <option>NATIONAL INVESTMENT BANK - SAWLA BRANCH</option>
                                            <option>
                                                NATIONAL INVESTMENT BANK - TARKWA BRANCH
                                            </option>
                                            <option>NATIONAL INVESTMENT BANK - UDS TAMALE B</option>
                                            <option>NATIONAL INVESTMENT BANK - YENDI</option>
                                            <option>NATIONAL INVESTMENT BANK HEAD OFFICE BR</option>
                                            <option>NATIONAL INVESTMENT BANK- AIRPORT CITY</option>
                                            <option>
                                                NATIONAL INVESTMENT BANK- LAW COURT COMP
                                            </option>
                                            <option>NATIONAL INVESTMENT BANK-ACCRA NEW TOWN</option>
                                            <option>
                                                NATIONAL INVESTMENT BANK-ACHIMOTA BRANCH
                                            </option>
                                            <option>NATIONAL INVESTMENT BANK-DANSOMAN</option>
                                            <option>NATIONAL INVESTMENT BANK-EAST LEGON</option>
                                            <option>NATIONAL INVESTMENT BANK-KASOA</option>
                                            <option>NATIONAL INVESTMENT BANK-KITAMPO</option>
                                            <option>NATIONAL INVESTMENT BANK-MAKOLA</option>
                                            <option>NATIONAL INVESTMENT BANK-NTOROSO</option>
                                            <option>NATIONAL INVESTMENT BANK-SPINTEX</option>
                                            <option>NATIONAL INVESTMENT BANK-SWEDRU BRANCH</option>
                                            <option>NATIONAL INVESTMENT BANK-TANOSO</option>
                                            <option>NATIONAL INVESTMENT BANK-WENCHI</option>
                                        </optgroup>
                                        <optgroup label="OMNIBANK GHANA LTD">
                                            <option>Omnibank Ghana Limited - Head Office</option>
                                            <option>Omnibank Ghana Limited - Kasoa</option>
                                            <option>Omnibank Ghana Limited - Koforidua</option>
                                            <option>Omnibank Ghana Limited - Abossey Okai</option>
                                            <option>Omnibank Ghana Limited - Ashaiman</option>
                                            <option>Omnibank Ghana Limited - Dome</option>
                                            <option>Omnibank Ghana Limited - Dzorwulu</option>
                                            <option>Omnibank Ghana Limited - East Legon</option>
                                            <option>Omnibank Ghana Limited - Kokomlemle</option>
                                            <option>Omnibank Ghana Limited - Madina</option>
                                            <option>Omnibank Ghana Limited - Nmai-Djorn</option>
                                            <option>Omnibank Ghana Limited - Osu</option>
                                            <option>Omnibank Ghana Limited - Spintext</option>
                                            <option>Omnibank Ghana Limited - Takoradi</option>
                                            <option>Omnibank Ghana Limited - Tarkwa</option>
                                            <option>Omnibank Ghana Limited - Tema 1</option>
                                            <option>Omnibank Ghana Limited - Tema 2</option>
                                            <option>Omnibank Ghana Limited - Tudu</option>
                                            <option>Omnibank Ghana Limited - Weija</option>
                                            <option>Omnibank Ghana LimitedÊ - Adum</option>
                                            <option>Omnibank Ghana LimitedÊ - Anloga</option>
                                            <option>Omnibank Ghana LimitedÊ - Kejetia</option>
                                            <option>Omnibank Ghana LimitedÊ - Kronum</option>
                                            <option>
                                                Omnibank Ghana LimitedÊ - Kumasi Central
                                            </option>
                                            <option>Omnibank Ghana LimitedÊ - Manhyia</option>
                                        </optgroup>
                                        <optgroup label="PREMIUM BANK">
                                            <option>
                                                Premium Bank Ghana Ltd - Accra Main Ridg
                                            </option>
                                            <option>
                                                Premium Bank Ghana Ltd - Windy Ridge Tak
                                            </option>
                                        </optgroup>
                                        <optgroup label="PRUDENTIAL BANK LTD">
                                            <option>PRUDENTIAL BANK GH LTD</option>
                                            <option>PRUDENTIAL BANK LTD -EAST LEGON</option>
                                            <option>PRUDENTIAL BANK LTD-ABEKA</option>
                                            <option>PRUDENTIAL BANK LTD-ABOABO</option>
                                            <option>PRUDENTIAL BANK LTD-ABOSSEY OKAI</option>
                                            <option>PRUDENTIAL BANK LTD-ACCRA</option>
                                            <option>PRUDENTIAL BANK LTD-ADENTA</option>
                                            <option>PRUDENTIAL BANK LTD-ADUM-KUMASI</option>
                                            <option>PRUDENTIAL BANK LTD-AFFUL NKWANTA</option>
                                            <option>PRUDENTIAL BANK LTD-ATONSU</option>
                                            <option>PRUDENTIAL BANK LTD-CAPECOAST BRANCH</option>
                                            <option>PRUDENTIAL BANK LTD-GICEL</option>
                                            <option>PRUDENTIAL BANK LTD-KUMASI</option>
                                            <option>PRUDENTIAL BANK LTD-KWAME NKR CIRCLE</option>
                                            <option>PRUDENTIAL BANK LTD-MADINA</option>
                                            <option>PRUDENTIAL BANK LTD-MAKOLA</option>
                                            <option>PRUDENTIAL BANK LTD-MATAHEKO</option>
                                            <option>
                                                PRUDENTIAL BANK LTD-NORTH INDUSTRIAL AREA
                                            </option>
                                            <option>PRUDENTIAL BANK LTD-ODORKOR</option>
                                            <option>PRUDENTIAL BANK LTD-RING ROAD CENTRAL</option>
                                            <option>PRUDENTIAL BANK LTD-SPINTEX ROAD</option>
                                            <option>PRUDENTIAL BANK LTD-SUAME MAAKRO</option>
                                            <option>PRUDENTIAL BANK LTD-TAKORADI HARBOUR</option>
                                            <option>
                                                PRUDENTIAL BANK LTD-TAKORADI MARKET CIRCLE
                                            </option>
                                            <option>PRUDENTIAL BANK LTD-TAMALE</option>
                                            <option>PRUDENTIAL BANK LTD-TEMA COMMUNITY 1</option>
                                            <option>PRUDENTIAL BANK LTD-TEMA FISHING HABOUR</option>
                                            <option>PRUDENTIAL BANK LTD-TESANO</option>
                                            <option>
                                                PRUDENTIAL BANK LTD-UNIV OF CAPE COAST BR
                                            </option>
                                            <option>PRUDENTIAL BANK LTD-WEIJA</option>
                                            <option>PRUDENTIAL BANK LTD-ZONGO JUNCTION</option>
                                        </optgroup>
                                        <optgroup label="ROYAL BANK">
                                            <option>ROYAL BANK - SPINTEX BANK</option>
                                            <option>ROYAL BANK CASTLE ROAD</option>
                                            <option>THE ROYAL BANK - ACHIMOTA</option>
                                            <option>THE ROYAL BANK - ARENA</option>
                                            <option>THE ROYAL BANK - GRAPHIC ROAD</option>
                                            <option>THE ROYAL BANK - MAAMOBI</option>
                                            <option>THE ROYAL BANK - NORTH IND AREA</option>
                                            <option>THE ROYAL BANK - PRIVATE BANKING</option>
                                            <option>THE ROYAL BANK - SEFWI</option>
                                            <option>THE ROYAL BANK - SOUTH LEGON</option>
                                            <option>THE ROYAL BANK - TEMA</option>
                                            <option>THE ROYAL BANK ABENA BRANCH</option>
                                            <option>THE ROYAL BANK ASHTOWN</option>
                                            <option>THE ROYAL BANK-KNUST</option>
                                            <option>THE ROYAL BANK-SUNYANI</option>
                                            <option>THE ROYAL BNK - ACCRA CENTRAL</option>
                                        </optgroup>
                                        <optgroup label="SG-SSB LTD">
                                            <option>SG SSB EAST LEGON BRANCH</option>
                                            <option>SG SSB LTD-ACCRA CENTRAL</option>
                                            <option>SG SSB LTD-ACCRA MAIN</option>
                                            <option>SG SSB LTD-ACCRA NEW TOWN</option>
                                            <option>SG SSB LTD-ADABOKROM</option>
                                            <option>SG SSB LTD-ADUM</option>
                                            <option>SG SSB LTD-AKIM ODA</option>
                                            <option>SG SSB LTD-AKONTOMBRA</option>
                                            <option>SG SSB LTD-ASANKRAGUA</option>
                                            <option>SG SSB LTD-ASEMPANEYE</option>
                                            <option>SG SSB LTD-BEREKUM</option>
                                            <option>SG SSB LTD-BIBIANI</option>
                                            <option>SG SSB LTD-BOLGATANGA</option>
                                            <option>SG SSB LTD-CAPE COAST</option>
                                            <option>SG SSB LTD-CENTRALISED OPERATIONS</option>
                                            <option>SG SSB LTD-DUNKWA</option>
                                            <option>SG SSB LTD-ESSAM</option>
                                            <option>SG SSB LTD-FAANOFA</option>
                                            <option>SG SSB LTD-HEAD OFFCE</option>
                                            <option>SG SSB LTD-HO</option>
                                            <option>SG SSB LTD-INTERNATIONAL BUSINESS</option>
                                            <option>SG SSB LTD-JUABESO</option>
                                            <option>SG SSB LTD-KANASHIE</option>
                                            <option>SG SSB LTD-KOFORIDUA</option>
                                            <option>SG SSB LTD-KOTOBABI</option>
                                            <option>SG SSB LTD-KUMASI CENTRAL</option>
                                            <option>SG SSB LTD-LOTTERIES</option>
                                            <option>SG SSB LTD-NORTH INDUSTRIAL AREA</option>
                                            <option>SG SSB LTD-OKAISHIE</option>
                                            <option>SG SSB LTD-OSU</option>
                                            <option>SG SSB LTD-PREMIER TOWERS</option>
                                            <option>SG SSB LTD-SEFWI BEKWAI</option>
                                            <option>SG SSB LTD-SEFWI WIAWSO</option>
                                            <option>SG SSB LTD-SUNYANI</option>
                                            <option>SG SSB LTD-TAKORADI</option>
                                            <option>SG SSB LTD-TAMALE</option>
                                            <option>SG SSB LTD-TARKWA</option>
                                            <option>SG SSB LTD-TEMA COMMUNITY 2</option>
                                            <option>SG SSB LTD-TEMA FISHINH HARBOUR</option>
                                            <option>SG SSB LTD-TEPA</option>
                                            <option>SG SSB LTD-TREASURY</option>
                                            <option>SG SSB LTD-TUDU</option>
                                            <option>SG SSB LTD-TWIFO NYINASE</option>
                                            <option>SG SSB LTD-WA</option>
                                            <option>SG SSB- Asafo</option>
                                            <option>SG SSB-ASHAIMAN</option>
                                            <option>SG-SSB LTD - KEJETIA</option>
                                            <option>SGS Madina</option>
                                            <option>SGS SPINTEX ROAD</option>
                                            <option>SOCIETE GENERALE GH LTD-SUAME</option>
                                            <option>SSB-KASOA</option>
                                            <option>SSB-TAKORADI MARKET CIRCLE</option>
                                            <option>SSB-TEMA COMMUNITY 1</option>
                                        </optgroup>
                                        <optgroup label="SOVEREIGN BANK">
                                            <option>SOVEREIGN BANK LTD-RIDGE</option>
                                        </optgroup>
                                        <optgroup label="STANBIC BANK">
                                            <option>STABIC BANK GHANA LIMITED - ADUM</option>
                                            <option>STANBIC BANK - EAST LEGON</option>
                                            <option>STANBIC BANK - MADINA BRANCH</option>
                                            <option>STANBIC BANK -STANBIC HEIGHTS BRANCH</option>
                                            <option>
                                                STANBIC BANK GH LTD - TEMA FISHING HABOUR
                                            </option>
                                            <option>
                                                STANBIC BANK GHANA -NORTH INDUSTIAL AREA
                                            </option>
                                            <option>STANBIC BANK GHANA LTD - LEGON</option>
                                            <option>STANBIC BANK GHANA LTD- ASHIAMAN</option>
                                            <option>STANBIC BANK GHANA LTD-ACCRA MAIN</option>
                                            <option>STANBIC BANK GHANA LTD-ACCRA MALL</option>
                                            <option>STANBIC BANK GHANA LTD-ACHIMOTA</option>
                                            <option>STANBIC BANK GHANA LTD-AIRPORT CITY</option>
                                            <option>STANBIC BANK GHANA LTD-BOLGATANGA</option>
                                            <option>STANBIC BANK GHANA LTD-DANSOMAN</option>
                                            <option>STANBIC BANK GHANA LTD-GRAPHIC ROAD</option>
                                            <option>STANBIC BANK GHANA LTD-HARPER-KUMASI</option>
                                            <option>STANBIC BANK GHANA LTD-HO</option>
                                            <option>STANBIC BANK GHANA LTD-KASOA</option>
                                            <option>STANBIC BANK GHANA LTD-LAPAZ</option>
                                            <option>STANBIC BANK GHANA LTD-MAKOLA</option>
                                            <option>STANBIC BANK GHANA LTD-MOVENPICK</option>
                                            <option>STANBIC BANK GHANA LTD-RIND ROAD</option>
                                            <option>STANBIC BANK GHANA LTD-ROMAN RIDGE</option>
                                            <option>STANBIC BANK GHANA LTD-SPINTEX ROAD</option>
                                            <option>STANBIC BANK GHANA LTD-SUAME</option>
                                            <option>STANBIC BANK GHANA LTD-SUNYANI</option>
                                            <option>STANBIC BANK GHANA LTD-TAKORADI</option>
                                            <option>STANBIC BANK GHANA LTD-TAMALE</option>
                                            <option>STANBIC BANK GHANA LTD-TARKWA</option>
                                            <option>STANBIC BANK GHANA LTD-WA</option>
                                            <option>STANBIC BANK GHANA-TEMA INDUSTRIAL AREA</option>
                                            <option>STANBIC BANK-JUNCTION MALL BRANCH</option>
                                            <option>STANBIC MADINA</option>
                                        </optgroup>
                                        <optgroup label="STANDARD CHARTERED BANK">
                                            <option>
                                                STANDARD CHARTERED BANK (GH) HIGH STREET
                                            </option>
                                            <option>STANDARD CHARTERED BANK KORLE DUDOR</option>
                                            <option>STANDARD CHARTERED BANK OPEIBEA HOUSE</option>
                                            <option>
                                                STANDARD CHARTERED BANK WESTLANDS BRANCH
                                            </option>
                                            <option>STANDARD CHARTERED BANK(GH) ABEKA</option>
                                            <option>STANDARD CHARTERED BANK(GH) DANSOMAN</option>
                                            <option>STANDARD CHARTERED BANK(GH) KEJETIA</option>
                                            <option>STANDARD CHARTERED BANK(GH) LEGON</option>
                                            <option>
                                                STANDARD CHARTERED BANK(GH) LIBERIA ROAD
                                            </option>
                                            <option>STANDARD CHARTERED BANK(GH) OBUASI</option>
                                            <option>STANDARD CHARTERED BANK(GH) OSU</option>
                                            <option>STANDARD CHARTERED BANK(GH) SPINTEX</option>
                                            <option>STANDARD CHARTERED BANK(GH) TAMALE</option>
                                            <option>STANDARD CHARTERED BANK(GH) TARKWA</option>
                                            <option>STANDARD CHARTERED BANK(GH) TECHIMAN</option>
                                            <option>STANDARD CHARTERED BANK(GH) TEMA</option>
                                            <option>STANDARD CHARTERED BANK(GH) TUDU</option>
                                            <option>STANDARD CHARTERED BANK(GH)-ACHIMOTA</option>
                                            <option>STANDARD CHARTERED BANK(GH)-MADINA</option>
                                            <option>STANDARD CHARTERED HARPER ROAD</option>
                                            <option>STANDARD CHARTERED RING ROAD CENTRAL</option>
                                            <option>STANDARD CHARTERED TAKORADI HARBOUR</option>
                                        </optgroup>
                                        <optgroup label="THE TRUST BANK LTD">
                                            <option>
                                                THE TRUST BANK LTD-UNIONSAVING AND LOANS
                                            </option>
                                            <option>THE TRUST BANK SPINTEX BRANCH</option>
                                            <option>THE TRUST BANK-ACCRA MAIN BRANCH</option>
                                            <option>THE TRUST BANK-ADEHERNAN SAVINGS LOANS</option>
                                            <option>THE TRUST BANK-ASHTOWN</option>
                                            <option>THE TRUST BANK-KANTAMANTO</option>
                                            <option>THE TRUST BANK-KASOA</option>
                                            <option>THE TRUST BANK-KISSEIMAN</option>
                                            <option>THE TRUST BANK-KUMASI MAIN BRANCH</option>
                                            <option>THE TRUST BANK-KWASHIEMAN</option>
                                            <option>THE TRUST BANK-MADINA</option>
                                            <option>THE TRUST BANK-OKOFO HOUSE</option>
                                            <option>THE TRUST BANK-POST OFFICE</option>
                                            <option>THE TRUST BANK-SAKUMONO</option>
                                            <option>THE TRUST BANK-SUAME MAGAZINE</option>
                                            <option>THE TRUST BANK-TEAM COMMUNITY I</option>
                                            <option>THE TRUST BANK-TEMA MAIN</option>
                                            <option>THE TRUST BANK-TESANO BRANCH</option>
                                            <option>THE TRUST BANK-TRUST TOWERS BRANCH</option>
                                            <option>
                                                THE TRUST BK-FIRST NATIONAL SAVING LOANS
                                            </option>
                                            <option>THE TRUST BK-WOMENS WORLD BANKING</option>
                                        </optgroup>
                                        <optgroup label="TIGO">
                                            <option>TIGO</option>
                                        </optgroup>
                                        <optgroup label="UNIBANK GHANA LTD">
                                            <option>UNIBANK -MADINA BRANCH</option>
                                            <option>UNIBANK BOLGATANGA</option>
                                            <option>UNIBANK GHANA LTD</option>
                                            <option>UNIBANK GHANA LTD ACCRA CENTRAL BRANCH</option>
                                            <option>UNIBANK GHANA LTD EAST LEGON</option>
                                            <option>UNIBANK GHANA LTD-ACCRA MAIN</option>
                                            <option>UNIBANK GHANA LTD-ACCRA MALL</option>
                                            <option>UNIBANK GHANA LTD-APENKWA</option>
                                            <option>UNIBANK GHANA LTD-ASHIAMAN</option>
                                            <option>UNIBANK GHANA LTD-DARKUMAN</option>
                                            <option>UNIBANK GHANA LTD-GIMPA</option>
                                            <option>UNIBANK GHANA LTD-KANESHIE</option>
                                            <option>UNIBANK GHANA LTD-KUMASI MAIN</option>
                                            <option>UNIBANK GHANA LTD-MAKOLA</option>
                                            <option>UNIBANK GHANA LTD-NORTH INDUSTRIAL AREA</option>
                                            <option>UNIBANK GHANA LTD-OSU</option>
                                            <option>UNIBANK GHANA LTD-OXFORD STREET BRANCH</option>
                                            <option>UNIBANK GHANA LTD-ROMAN HILL BRANCH</option>
                                            <option>UNIBANK GHANA LTD-SPINTEX ROAD</option>
                                            <option>UNIBANK GHANA LTD-SUAME</option>
                                            <option>UNIBANK GHANA LTD-TAFO BRANCH</option>
                                            <option>UNIBANK GHANA LTD-TAKORADI</option>
                                            <option>UNIBANK GHANA LTD-TECHIMAN NORTH BRANCH</option>
                                            <option>UNIBANK GHANA LTD-TEMA</option>
                                            <option>UNIBANK GHANA LTD-WORLD TRADE CENTRE</option>
                                            <option>UNIBANK TAMALE BRANCH</option>
                                            <option>UNIBANK TEMA COM 25 BRANCH</option>
                                        </optgroup>
                                        <optgroup label="UNITED BANK FOR AFRICA">
                                            <option>UBA - SUAME BRANCH</option>
                                            <option>UBA AIRPORT BRANCH</option>
                                            <option>UBA BANK ABEKA LAPAZ</option>
                                            <option>
                                                UBA NORTH INDUSTRIAL AREA BUSINESS OFFICE
                                            </option>
                                            <option>UNITED BANK FOR AFRICA(GH) LTD-MADINA</option>
                                            <option>UNITED BANK FOR AFRICA-ABOSSEYOKAI</option>
                                            <option>UNITED BANK FOR AFRICA-ACCRA CENTRAL</option>
                                            <option>UNITED BANK FOR AFRICA-DZORWULU 2</option>
                                            <option>UNITED BANK FOR AFRICA-EAST LEGON 1</option>
                                            <option>UNITED BANK FOR AFRICA-EAST LEGON 2</option>
                                            <option>
                                                UNITED BANK FOR AFRICA-HEAD OFFICE-RIDGE
                                            </option>
                                            <option>UNITED BANK FOR AFRICA-KUMASI-ADUM</option>
                                            <option>UNITED BANK FOR AFRICA-RING ROAD</option>
                                            <option>UNITED BANK FOR AFRICA-SPINTEX ROAD</option>
                                            <option>UNITED BANK FOR AFRICA-TAKORADI</option>
                                            <option>UNITED BANK FOR AFRICA-TEMA</option>
                                            <option>UNITED BANK FOR AFRICA-TESHIE</option>
                                            <option>
                                                UNITED BANK FOR AFRICA-DZORWULU 1MOTORWAY
                                            </option>
                                        </optgroup>
                                        <optgroup label="UNIVERSAL MERCHANT BANK">
                                            <option>MERCHANT BANK - MBG-KONONGO BRANCH</option>
                                            <option>MERCHANT BANK (GH) LTD-ABEKA BRANCH</option>
                                            <option>MERCHANT BANK (GH) LTD-ABOSEY OKAI</option>
                                            <option>MERCHANT BANK (GH) LTD-ACCRA</option>
                                            <option>MERCHANT BANK (GH) LTD-ACHIMOTA</option>
                                            <option>MERCHANT BANK (GH) LTD-ADABRAKA</option>
                                            <option>MERCHANT BANK (GH) LTD-ADUM</option>
                                            <option>MERCHANT BANK (GH) LTD-ASAFO</option>
                                            <option>MERCHANT BANK (GH) LTD-BANTAMA</option>
                                            <option>MERCHANT BANK (GH) LTD-BIBIANI</option>
                                            <option>MERCHANT BANK (GH) LTD-KANESHIE</option>
                                            <option>MERCHANT BANK (GH) LTD-KOFORIDUA</option>
                                            <option>MERCHANT BANK (GH) LTD-KUMASI</option>
                                            <option>MERCHANT BANK (GH) LTD-NORTH INDUSTRIAL</option>
                                            <option>MERCHANT BANK (GH) LTD-OSU</option>
                                            <option>MERCHANT BANK (GH) LTD-RIDGE</option>
                                            <option>MERCHANT BANK (GH) LTD-TAKORADI</option>
                                            <option>MERCHANT BANK (GH) LTD-TARKWA</option>
                                            <option>MERCHANT BANK (GH) LTD-TECHIMAN</option>
                                            <option>MERCHANT BANK (GH) LTD-TEMA</option>
                                            <option>MERCHANT BANK (GH) LTD-TEMA EAST</option>
                                            <option>
                                                MERCHANT BANK (GH)-SOUTH INDUSTRIAL AREA
                                            </option>
                                            <option>MERCHANT BANK GH LTD- AIRPORT CITY</option>
                                            <option>MERCHANT BANK(GH)LTD-FIRST NATIONAL S N</option>
                                            <option>UNIVERSAL MERCHANT BANK-LABONE</option>
                                        </optgroup>
                                        <optgroup label="UT BANK">
                                            <option>UT BANK (GH) LTD- ADABRAKA</option>
                                            <option>UT BANK (GH) LTD- Madina Branch</option>
                                            <option>UT BANK (GH) LTD-ABOSSEY OKAI</option>
                                            <option>UT BANK (GH) LTD-ADUM KUMASI</option>
                                            <option>UT BANK (GH) LTD-AIRPORT CITY</option>
                                            <option>UT BANK (GH) LTD-CASTLE ROAD</option>
                                            <option>UT BANK (GH) LTD-HAATSO</option>
                                            <option>UT BANK (GH) LTD-MAKOLA</option>
                                            <option>UT BANK (GH) LTD-NIMA</option>
                                            <option>UT BANK (GH) LTD-SPINTEX</option>
                                            <option>UT BANK (GH) LTD-TEMA COMMUNITY 2</option>
                                            <option>UT BANK (GH) LTD-TEMA DRY DOCK</option>
                                            <option>UT BANK - Takoradi Shippers Council</option>
                                            <option>UT BANK GHANA - OSU</option>
                                            <option>UT BANK TAKORADI SHIPPERS COUNCIL</option>
                                            <option>UT BANK(GH)LTD -ACCRA</option>
                                            <option>UT BANK(GH)LTD -ASHIAMAN</option>
                                            <option>UT BANK(GH)LTD -BAATSONAA</option>
                                            <option>UT BANK(GH)LTD -BEIGE CAPITAL</option>
                                            <option>UT BANK(GH)LTD -CAPE COAST</option>
                                            <option>UT BANK(GH)LTD -CORPORATE BANKING</option>
                                            <option>UT BANK(GH)LTD -HO</option>
                                            <option>UT BANK(GH)LTD -KANESHIE</option>
                                            <option>UT BANK(GH)LTD -KOFORIDUA</option>
                                            <option>UT BANK(GH)LTD -KUMASI STC</option>
                                            <option>UT BANK(GH)LTD -OBUASI</option>
                                            <option>UT BANK(GH)LTD -SUNYANI MELCOM</option>
                                            <option>UT BANK(GH)LTD -TADI MARKET CIRCLE</option>
                                            <option>UT BANK(GH)LTD-TARKWA BRANCH</option>
                                        </optgroup>
                                        <optgroup label="Vodafone">
                                            <option>Vodafone</option>
                                        </optgroup>
                                        <optgroup label="ZENITH BANK">
                                            <option>ZENITH BANK - Adum</option>
                                            <option>ZENITH BANK - CAPE COAST BRANCH</option>
                                            <option>ZENITH BANK - Tamale</option>
                                            <option>ZENITH BANK SUNYANI BRANCH</option>
                                            <option>ZENITH BANK (GH) LTD-AKOSOMBO BRANCH</option>
                                            <option>ZENITH BANK (GH) LTD-GRAPHIC ROAD</option>
                                            <option>ZENITH BANK (GH) LTD-KUMASI MAIN</option>
                                            <option>ZENITH BANK (GH) LTD-LABONE</option>
                                            <option>ZENITH BANK (GH) LTD-PREMIER TOWERS</option>
                                            <option>ZENITH BANK (GH) LTD-TAKORADI MAIN</option>
                                            <option>ZENITH BANK (GH) LTD-TEMA</option>
                                            <option>ZENITH BANK (GH)-NORTH INDUSTRIAL AREA</option>
                                            <option>ZENITH BANK (GH)-PATRICE LUMUMBA ROAD</option>
                                            <option>ZENITH BANK (GHANA) LTD - SUAME BRANCH</option>
                                            <option>ZENITH BANK - ACHIMOTA</option>
                                            <option>ZENITH BANK freezone branch</option>
                                            <option>ZENITH BANK GHANA LTD- SPINTEX</option>
                                            <option>ZENITH BANK GHANA LTD-EAST LEGON</option>
                                            <option>ZENITH BANK GHANA-KOJO THOMPSON RD BR</option>
                                            <option>ZENITH BANK LTD TEMA IND AREA</option>
                                            <option>ZENITH BANK- TEMA METRO</option>
                                            <option>ZENITH-TAKORADI HARBOUR</option>
                                            <option>ZENITH-TARKWA</option>
                                            <option>ZENITH-TEMA METRO</option>
                                        </optgroup>

                                        <!--option >ADB Bank Limited</option>
                                        <option >Bank of Africa, Ghana Ltd</option>
                                        <option >Bank of Baroda (Ghana) Limited</option>
                                        <option >Banque Sahélo-Saharienne pour I’Investissement et le Commerce BSIC (Ghana) Limited</option>
                                        <option >Barclays Bank of Ghana Ltd</option>
                                        <option >CAL Bank Limited</option>
                                        <option >Consolidated Bank Ghana Limited</option>
                                        <option >Ecobank Ghana Limited</option>
                                        <option >Fidelity Bank Limited</option>
                                        <option >First Atlantic Bank Ltd</option>
                                        <option >First National Bank (Ghana) Ltd</option>
                                        <option >GCB Bank Limited</option>
                                        <option >GN Bank Limited</option>
                                        <option >Guaranty Trust Bank (Ghana) Limited</option>
                                        <option >Republic Bank (Ghana) Ltd</option>
                                        <option >National Investment Bank Ltd</option>
                                        <option >Prudential Bank Limited</option>
                                        <option >Societe General (Ghana) Limited</option>
                                        <option >Stanbic Bank Ghana Ltd</option>
                                        <option >Standard Chartered Bank (Ghana) Limited</option>
                                        <option >United Bank for Africa (Ghana) Ltd.</option>
                                        <option >Universal Merchant Bank Ltd</option>
                                        <option >Zenith Bank (Ghana) Limited</option>
                                        <option >Premium Bank Ltd</option>
                                        <option >OmniBank Ghana Limited</option>
                                        <option >Heritage Bank Limited</option>
                                        <option >GHL Bank Limited</option>
                                        <option >ARB Apex Bank Ltd</option -->
                                    </select>
                                    <select v-else-if="currentUser.country.toLowerCase() == 'kenya'" v-model="bank" class="form-control populate" name="bank-select" id="bank" required>
                                        <option value="" selected="selected">
                                            - Choose Your Bank -
                                        </option>
                                        <option>African Banking Corp. Bank Ltd</option>
                                        <option>Bank of Africa Kenya Limited</option>
                                        <option>Barclays Bank of Kenya</option>
                                        <option>CFC Stanbic Bank Kenya Limited</option>
                                        <option>Chase Bank Limited</option>
                                        <option>Citibank N.A.</option>
                                        <option>Co-operative Bank of Kenya Limited</option>
                                        <option>Commercial Bank of Africa Limited</option>
                                        <option>Consolidated Bank of Kenya Limited</option>
                                        <option>Credit Bank Limited</option>
                                        <option>Diamond Trust Bank Limited</option>
                                        <option>Dubai Bank Kenya Limited</option>
                                        <option>Equatorial Commercial Bank Limited</option>
                                        <option>Equity Bank Limited</option>
                                        <option>Family Bank Ltd</option>
                                        <option>First Community Bank</option>
                                        <option>Guaranty Trust Bank</option>
                                        <option>Guardian Bank Limited</option>
                                        <option>Gulf African Bank Ltd</option>
                                        <option>Housing Finance Bank</option>
                                        <option>Imperial Bank Limited</option>
                                        <option>Investments n Mortgages Bank Limited</option>
                                        <option>Jamii Bora Bank</option>
                                        <option>K-Rep Bank Limited</option>
                                        <option>Kenya Commercial Bank Limited</option>
                                        <option>Middle East Bank Kenya Limited</option>
                                        <option>MPESA</option>
                                        <option>National Bank of Kenya Limited</option>
                                        <option>NIC Bank Limited</option>
                                        <option>Oriental Commercial Bank Limited</option>
                                        <option>Paramount Universal Bank Limited</option>
                                        <option>Prime Bank Limited</option>
                                        <option>Trans-National Bank Limited</option>
                                        <option>UBA Kenya Bank Ltd</option>
                                        <option>Victoria Commercial Bank Limited</option>
                                    </select>
                                    <select v-else v-model="bank" name="bank-select" id="bank" required="required" class="form-control populate">
                                        <option value="" selected="selected">
                                            - Choose Your Bank -
                                        </option>
                                        <option value="access">Access Bank</option>
                                        <option value="citibank">Citibank</option>
                                        <option value="diamond">Diamond Bank</option>
                                        <option value="ecobank">Ecobank</option>
                                        <option value="fidelity">Fidelity Bank</option>
                                        <option value="fbn">First Bank of Nigeria</option>
                                        <option value="fcmb">
                                            First City Monument Bank (FCMB)
                                        </option>
                                        <option>FSDH Merchant Bank</option>
                                        <option value="gtb">Guarantee Trust Bank (GTB)</option>
                                        <option value="heritage">Heritage Bank</option>
                                        <option value="Keystone">Keystone Bank</option>
                                        <option>Jaiz Bank Plc</option>
                                        <option value="rand">Rand Merchant Bank</option>
                                        <option value="skye">Skye Bank (Polaris Bank)</option>
                                        <option value="stanbic">Stanbic IBTC Bank</option>
                                        <option value="standard">Standard Chartered Bank</option>
                                        <option value="sterling">Sterling Bank</option>
                                        <option value="suntrust">Suntrust Bank</option>
                                        <option value="union">Union Bank</option>
                                        <option value="uba">United Bank for Africa (UBA)</option>
                                        <option value="unity">Unity Bank</option>
                                        <option value="wema">Wema Bank</option>
                                        <option value="zenith">Zenith Bank</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-asterisk"></i></span>
                                    <select v-model="account_type" name="account-type-select" id="account_type" required="required" class="form-control populate">
                                        <option value="" selected="selected">
                                            - Select Bank Account Type -
                                        </option>
                                        <option value="Savings">Savings</option>
                                        <option value="Current">Current</option>
                                        <option value="Fixed">Fixed</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <button @click="addBankAccount" class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center">
                                Update Bank Info
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4">
            <div class="panel panel-primary">
                <header class="panel-heading">
                    <h2 class="panel-title">Change Password</h2>
                </header>
                <div class="panel-body">
                    <div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-lock"></i></span>
                                    <input type="password" name="user-current-password" v-model="current_password" placeholder="Current Password" minlength="6" required="required" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-shield"></i></span>
                                    <input type="password" name="user-new-password" v-model="new_password" placeholder="New Password" minlength="6" required="required" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon"><i class="fa fa-shield"></i></span>
                                    <input type="password" name="confirm-user-new-password" v-model="confirm_new_password" placeholder="Confirm New Password" minlength="6" required="required" class="form-control" />
                                </div>
                            </div>
                        </div>
                        <div class="row text-center">
                            <button @click="changePasswordFromSettings" type="submit" class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center">
                                Create Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";

export default {
    name: "Wallet",
    components: {},
    data() {
        return {
            user: "",
            currentUser: {},
            thisUser: {},
            name: "",
            account_no: "",
            account_type: "",
            bank: "",
            bank_account: "",
            wallet: "",
            currency: "",
            subscription: "",
            social_name: "",
            subscription: "",
            current_password: "",
            new_password: "",
            confirm_new_password: "",
            facebook: "",
            twitter: "",
            youtube: "",
            instagram: "",
            tiktok: "",
            threads: "",
            pc: "",
            social: "",
            percent: "",
            sc_plan: "",
            sc_referred: "",
            sc_social: "",
            sc_task: "",
            loading: true,
            toReceive: 0,
            page: 0,
            per_page: 25,
        };
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser;
        this.id = this.thisUser["id"];
        this.getWallet();
        this.profileCompletion();
        this.getBankAccount();
        this.getSocialAccounts();
    },
    methods: {
        async getWallet() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.GetWallet();
            // console.log('GetMe',apiResponse)
            if (apiResponse["status"] == "success") {
                this.wallet = apiResponse["data"];
                this.currency = apiResponse["currency"];
                this.currentUser = apiResponse["user"];
                if (apiResponse["subscription"]) {
                    this.subscription = apiResponse["subscription"]["plan"];
                }
            }
            this.toggleProgress("hide");
        },
        async getBankAccount() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.GetBankAccount();
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
                this.bank_account = apiResponse["data"];
                this.account_no = this.bank_account["account_no"];
                this.bank = this.bank_account["bank"];
                this.account_type = this.bank_account["account_type"];
                this.name = this.bank_account["name"];
            }
            this.toggleProgress("hide");
        },
        async addBankAccount() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.AddBankAccount(
                this.name,
                this.account_no,
                this.bank,
                this.account_type
            );
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] == "success") {
                // location.href = ''
                this.getBankAccount();
            }
            this.toggleProgress("hide");
        },
        async getSocialAccounts() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.GetSocialAccounts(
                this.page,
                this.per_page
            );
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
                this.social = apiResponse["data"];

                if(this.social){
                    this.facebook = this.social.facebook
                    this.twitter = this.social.twitter
                    this.instagram = this.social.instagram
                    this.youtube = this.social.youtube
                    this.tiktok = this.social.tiktok
                    this.threads = this.social.threads
                }
            }
            this.toggleProgress("hide");
        },
        async addSocialAccount() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.AddSocialAccount(
                $("#facebook").val(),
                $("#twitter").val(),
                $("#youtube").val(),
                $("#instagram").val(),
                $("#tiktok").val(),
                $("#threads").val(),
            );
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] == "success") {
                // location.href = ''
                this.getSocialAccounts();
            }
            this.toggleProgress("hide");
        },
        async changePasswordFromSettings() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.ChangePasswordFromProfile(
                this.current_password,
                this.new_password,
                this.confirm_new_password
            );
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] == "success") {}
            this.toggleProgress("hide");
        },
        async profileCompletion() {
            this.toggleProgress("show");
            let apiResponse = await ApiService.ProfileCompletion(this.amount);
            if (apiResponse["status"] == "success") {
                this.percent = apiResponse["percent"];
                this.sc_social = apiResponse["social"];
                this.sc_task = apiResponse["task"];
                this.sc_referred = apiResponse["referred"];
                this.sc_plan = apiResponse["plan"];
            }
            this.toggleProgress("hide");
        },
    },
};
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #fff;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
