<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Dashboard</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li><a href="dashboard.html"><i class="fa fa-home"></i></a></li>
            </ol>
        </div>
    </header>
    <div class="panel">
        <div class="row">
            <div class="col-md-6">
                <h2 class="create-advert-intro">New Tasks</h2>
                <h4 class="text-bold ">Below are social media tasks you can earn from.</h4>
            </div>
            <div class="col-md-6 viral-card panel-body">
                <h4 class="text-bold mb-xs mt-xs mr-xs btn btn-md btn-primary">Your Referral Link..</h4> <a class="pl-md pr-md pt-xs pb-xs text-center" style="font-size: 15px;">{{ refLink }}</a>
            </div>
            <div class="col-xs-12" v-if="!loadingSocial && !facebook && !twitter && !instagram && !youtube && !tiktok && !threads">
                <div id="socialButton mt-3" class="text-center"><a href="/dashboard/profile" class="btn btn-primary">Add Social Media Accounts</a></div>
            </div>
        </div>
        <br><br>
        <a v-if="ad && !ad['google_ad_code']" :href="ad['link']" target="_blank" @click="clickPostAd">
            <img :src="ad['image']" class="img-responsive" style="max-height: 95px; margin: auto;">
        </a>
        <br><br>
        <div class="row text-center posts-card">
            <div class="col-md-12">
                <h2 class="create-advert-intro">POST TASKS OF THE DAY</h2>
                <p>Click on Post links Below, Read and make relevant Comments to Earn(The System Only Pays Relevant Comments)</p>
                <hr> <br>
            </div>
            <div class="col-xs-12">
                <div class="text-center"></div>
            </div>
            <div class="col-xs-12">
                <div class="text-center" v-for="post in posts">
                    <a>
                        <span class="time-posted">{{ formatDateTimeNice(post.created_at) }}</span>
                        <span class="text">{{ post.topic }}</span>
                    </a>
                    <br>
                </div>
            </div>
        </div> <br><br>
        <div id="cards" class="row  mt-lg">
            <div class="text-center" v-if="loadingTasks == true">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
            <div v-for="task in tasks" class=" col-sm-6 col-md-6 col-lg-4 mt-sm mb-sm">
                <div class="panel-body task_height_old">
                    <header class="panel-heading portlet-handler support-pending-ticket">
                        <div class="panel-actions task-time">
                            <strong>
                                <span class="timer" data-seconds-left="200"></span>
                            </strong>
                        </div>
                        <h2 class="panel-title">
                            <span class="text-uppercase">
                                {{ task['platform'] }} ({{ task['type'] }})
                            </span>
                        </h2>
                    </header>
                    <div class="mt-xs task_info">
                        <template v-if="task['platform'] == 'instagram' || task['platform'] == 'tiktok' || task['platform'] == 'twitter' || task['platform'] == 'threads'">
                            <span style="color:red;" class="bold">
                                Note: Use {{ task['platform'] }} app to perform all {{ task['platform'] }} task<br>
                                You must use the same browser to complete all other tasks, to avoid not getting paid by our system.
                            </span><br>
                            <span style="color:red;">
                                Note: You must be logged into the required social media account before performing task.
                            </span><br>
                            Perform only the task you are required to and do not close the page or popup opened by the platfrom.<br>
                            <span style="color: red;">
                                Note: Not performing of tasks will lead to account blockage/ban.
                            </span><br>
                            <span style="color: red;">
                                Note: if you have already performed the task before, we will still pay you, do not unfollow to follow or unlike to like.
                            </span><br>
                        </template>
                        <template v-else-if="task['platform'] == 'youtube' || task['platform'] == 'facebook'">
                            <span style="color:red;" class="bold">
                                Note: Use {{ task['platform'] }} app/website to perform all {{ task['platform'] }} task<br>
                                You must use the same browser to complete all other tasks, to avoid not getting paid by our system.
                            </span><br>
                            <span style="color:red;">
                                Note: You must be logged into the required social media account before performing task.
                            </span><br>
                            Perform only the task you are required to and do not close the page or popup opened by the platfrom.<br>
                            <span style="color: red;">
                                Note: Not performing of tasks will lead to account blockage/ban.
                            </span><br>
                            <span style="color: red;">
                                Note: if you have already performed the task before, we will still pay you, do not unfollow to follow or unlike to like.
                            </span><br>
                        </template>
                        <template v-else>
                            <span style="color:red;">
                                Note: You must be logged into the required social media account before performing task.
                            </span><br>
                            Perform only the task you are required to and do not close the page or popup opened by the platfrom.<br>
                            <span style="color: red;">
                                Note: Not performing of tasks will lead to account blockage/ban.
                            </span><br>
                        </template>
                        <p v-if="task['comment']" style="color: green;margin-top: 5px;">
                            Comment: {{ task['comment'] }}
                        </p>
                    </div>
                    <span class="mb-sm mt-sm btn btn-sm viral-btn btn-warning">
                        Reward&nbsp;/&nbsp;
                        <span class="  ">
                            <strong>
                                ₦ &nbsp;
                                {{ formatAmount(task['amount']) }}
                            </strong>
                        </span>
                    </span>
                    <a @click="setTask(task)" class="mb-sm mt-sm btn btn-sm btn btn-primary modal-with-move-anim viral-btn btn-md text-center pull-right" data-target=".task_modal" data-toggle="modal">
                        View more
                    </a>
                </div>
            </div>
            <!-- <div class=" col-sm-6 col-md-6 col-lg-4 mt-sm mb-sm">
                <div class="panel-body task_height">
                    <header class="panel-heading portlet-handler support-pending-ticket">
                        <div class="panel-actions task-time">
                            <strong>
                                <span class="timer" data-seconds-left="200"></span>
                            </strong>
                        </div>
                        <h2 class="panel-title">
                            <span class="text-uppercase">
                                instagram (like)
                            </span>
                        </h2>
                    </header>
                    <p class="mt-xs">
                        <span style="color:red;" class="bold">Note: Use Instagram app to perform all Instagram task<br>
                            You must use the same browser to complete all other tasks, to avoid not getting paid by our system.</span><br>
                        <span style="color:red;">Note: You must be logged into the required social media account before performing task.</span><br>
                        Perform only the task you are required to and do not close the page or popup opened by the platfrom.<br>
                        <span style="color: red;">Note: Not performing of tasks will lead to account blockage/ban.</span><br>
                        <span style="color: red;">Note: if you have already performed the task before, we will still pay you, do not unfollow to follow or unlike to like.</span>

                    </p>
                    <span class="mb-sm mt-sm btn btn-sm viral-btn btn-warning">
                        Reward&nbsp;/&nbsp;
                        <span class="  ">
                            <strong>
                                ₦ &nbsp;
                                3
                            </strong>
                        </span>
                    </span>
                    <a class="mb-sm mt-sm btn btn-sm btn btn-primary modal-with-move-anim viral-btn btn-md text-center pull-right" data-target="#taskDetailsf9V6d803d9" data-toggle="modal">
                        View more
                    </a>
                </div>
                <div id="taskDetailsf9V6d803d9" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading portlet-handler support-pending-ticket">
                            <div class="panel-actions task-time">
                                <strong>
                                    <span class="timer" data-seconds-left="200"></span>
                                </strong>
                            </div>
                            <h2 class="panel-title">
                                <span class="text-uppercase">
                                    instagram (like)
                                </span>
                                <hr>
                            </h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-text text-center">
                                <p class="advert-task-modal-text text-bold">Accept and Complete this task to earn it's reward.</p>
                                <p class="advert-task-modal-text text-bold">
                                    <span id="task-url" class="" style="word-break: break-word;">
                                        Please Adhere strictly to our rules and follow the instructions of the Advertiser to avoid payment issues or getting banned
                                    </span>
                                </p>
                                <p class="advert-task-modal-text text-bold">
                                    Reward :&nbsp;&nbsp;
                                    <span class="pl-xs pr-xs pt-xs pb-xs label label-lg label-warning">
                                        <strong>
                                            ₦ &nbsp;
                                            3
                                        </strong>
                                    </span>
                                </p>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span style="background-color: rgb(255, 0, 0); color: rgb(0, 0, 255); font-weight: bold;">MUST READ BEFORE YOU CONTINUE</span></p>
                                <p><b>1. Please ensure to do all Instagram tasks with Instagram App. If you do not have Instagram App kindly go to the App Store in your phone and download it for free</b></p>
                                <p><b><br></b></p>
                                <p><b>2. Never you do tasks with Instagram handle that doesn't have a profile picture and without posts. Your Instagram handle must have a profile picture that is yours and post regularly to stay active</b></p>
                                <p><b><br></b></p>
                                <p><b>3. Never use a shadow banned Instagram handle to do any task as it does not actually count. Wait until Instagram lifts the shadow banning or report to us to remove the Instagram handle and you input a new active handle. Doing this to cheat us will lead to us banning your account forever</b></p>
                                <p><b style="background-color: rgb(255, 0, 0);">Please follow all this rules to avoid being banned!</b></p>
                                <p><br></p>
                                <p></p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <button class="btn btn-primary viral-btn c" onclick="acceptTask('f9V6d803d9')">
                                        Accept Task
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
            </div>
            <div class=" col-sm-6 col-md-6 col-lg-4 mt-sm mb-sm">
                <div class="panel-body task_height">
                    <header class="panel-heading portlet-handler support-pending-ticket">
                        <div class="panel-actions task-time">
                            <strong>
                                <span class="timer" data-seconds-left="200"></span>
                            </strong>
                        </div>
                        <h2 class="panel-title">
                            <span class="text-uppercase">
                                instagram (comment)
                            </span>
                        </h2>
                    </header>
                    <p class="mt-xs">
                        <span style="color:red;" class="bold">Note: Use Instagram app to perform all Instagram task<br>
                            You must use the same browser to complete all other tasks, to avoid not getting paid by our system.</span><br>
                        <span style="color:red;">Note: You must be logged into the required social media account before performing task.</span><br>
                        Perform only the task you are required to and do not close the page or popup opened by the platfrom.<br>
                        <span style="color: red;">Note: Not performing of tasks will lead to account blockage/ban.</span><br>
                        <span style="color: red;">Note: if you have already performed the task before, we will still pay you, do not unfollow to follow or unlike to like.</span>

                    </p>
                    <span class="mb-sm mt-sm btn btn-sm viral-btn btn-warning">
                        Reward&nbsp;/&nbsp;
                        <span class="  ">
                            <strong>
                                ₦ &nbsp;
                                3
                            </strong>
                        </span>
                    </span>
                    <a class="mb-sm mt-sm btn btn-sm btn btn-primary modal-with-move-anim viral-btn btn-md text-center pull-right" data-target="#taskDetailsf3a01373f8" data-toggle="modal">
                        View more
                    </a>
                </div>
                <div id="taskDetailsf3a01373f8" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading portlet-handler support-pending-ticket">
                            <div class="panel-actions task-time">
                                <strong>
                                    <span class="timer" data-seconds-left="200"></span>
                                </strong>
                            </div>
                            <h2 class="panel-title">
                                <span class="text-uppercase">
                                    instagram (comment)
                                </span>
                                <hr>
                            </h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-text text-center">
                                <p class="advert-task-modal-text text-bold">Accept and Complete this task to earn it's reward.</p>
                                <p class="advert-task-modal-text text-bold">
                                    <span id="task-url" class="" style="word-break: break-word;">
                                        Please Adhere strictly to our rules and follow the instructions of the Advertiser to avoid payment issues or getting banned
                                    </span>
                                </p>
                                <p class="advert-task-modal-text text-bold">
                                    Reward :&nbsp;&nbsp;
                                    <span class="pl-xs pr-xs pt-xs pb-xs label label-lg label-warning">
                                        <strong>
                                            ₦ &nbsp;
                                            3
                                        </strong>
                                    </span>
                                </p>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span style="background-color: rgb(255, 0, 0); color: rgb(0, 0, 255); font-weight: bold;">MUST READ BEFORE YOU CONTINUE</span></p>
                                <p><b>1. Please ensure to do all Instagram tasks with Instagram App. If you do not have Instagram App kindly go to the App Store in your phone and download it for free</b></p>
                                <p><b><br></b></p>
                                <p><b>2. Never you do tasks with Instagram handle that doesn't have a profile picture and without posts. Your Instagram handle must have a profile picture that is yours and post regularly to stay active</b></p>
                                <p><b><br></b></p>
                                <p><b>3. Never use a shadow banned Instagram handle to do any task as it does not actually count. Wait until Instagram lifts the shadow banning or report to us to remove the Instagram handle and you input a new active handle. Doing this to cheat us will lead to us banning your account forever</b></p>
                                <p><b style="background-color: rgb(255, 0, 0);">Please follow all this rules to avoid being banned!</b></p>
                                <p><br></p>
                                <p></p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <button class="btn btn-primary viral-btn c" onclick="acceptTask('f3a01373f8')">
                                        Accept Task
                                    </button>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
            </div> -->
            <div id="task_modal" class="modal fade task_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <section class="panel modal-dialog" role="document">
                    <header class="panel-heading portlet-handler support-pending-ticket">
                        <div class="panel-actions task-time">
                            <strong>
                                <span class="timer" data-seconds-left="200"></span>
                            </strong>
                        </div>
                        <h2 class="panel-title">
                            <span class="text-uppercase">
                                {{ current_task['platform'] }} ({{ current_task['type'] }})
                            </span>
                            <hr>
                        </h2>
                    </header>
                    <div class="panel-body">
                        <div class="modal-text text-center">
                            <p class="advert-task-modal-text text-bold">Accept and Complete this task to earn it's reward.</p>
                            <p class="advert-task-modal-text text-bold">
                                <span id="task-url" class="" style="word-break: break-word;">
                                    Please Adhere strictly to our rules and follow the instructions of the Advertiser to avoid payment issues or getting banned
                                </span>
                            </p>
                            <p class="advert-task-modal-text text-bold">
                                Reward :&nbsp;&nbsp;
                                <span class="pl-xs pr-xs pt-xs pb-xs label label-lg label-warning">
                                    <strong>
                                        ₦ &nbsp;
                                        {{ formatAmount(current_task['amount']) }}
                                    </strong>
                                </span>
                            </p>
                        </div>
                        <div class="text-center advert-task-modal-warning" v-html="setTaskFirstWarning(current_task)">
                        </div>

                    </div>
                    <footer class="panel-footer">
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button class="btn btn-default modal-dismiss" data-dismiss="modal" @click="openLink('')">Cancel</button>
                                <button v-if="currentTaskButton == 'accept'" class="btn btn-primary viral-btn c" @click="acceptTask(current_task['url'])">
                                    Accept Task
                                </button>
                                <button v-else class="btn btn-primary viral-btn c" @click="performTask(current_task['url'], current_task['id'])">
                                    Perform Task
                                </button>
                            </div>
                        </div>
                    </footer>
                </section>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: 'Dashboard',
    components: {},
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            bank_account: '',
            social: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            tiktok: '',
            threads: '',
            tasks: '',
            refLink: '',
            loading: false,
            loadingBA: false,
            loadingTasks: false,
            loadingSocial: false,
            current_task: '',
            first_warning: '',
            second_warning: '',
            currentTaskButton: 'perform',
            ad: '',
            posts: '',
            nWind: '',
            event: 0,
            toReceive: 0,
            page: 0,
            per_page: 25
        }
    },
    beforeMount () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.getTasks()
        this.getWallet()
        this.getSocialAccounts()
        this.GetDashboardExtra()
    },
    methods: {
        async getTasks() {
            this.loadingTasks = true
            let apiResponse = await ApiService.GetTasks();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.first_warning = apiResponse['first_warning']
                this.second_warning = apiResponse['second_warning']
                this.tasks = apiResponse['data']
            }
            this.loadingTasks = false
        },
        async getWallet() {
            this.loading = true
            let apiResponse = await ApiService.GetWallet();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.wallet = apiResponse['data']
                this.currency = apiResponse['currency']
                this.currentUser = apiResponse['user']

                this.refLink = this.$store.getters["host/appUrl"] + '/register/' + this.currentUser['id']
            }
            this.loading = false
        },
        async getSocialAccounts() {
            this.toggleProgress("show");
            this.loadingSocial = true
            let apiResponse = await ApiService.GetSocialAccounts(
                this.page,
                this.per_page
            );
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
                this.social = apiResponse["data"];

                if (this.social) {
                    this.facebook = this.social.facebook
                    this.twitter = this.social.twitter
                    this.instagram = this.social.instagram
                    this.youtube = this.social.youtube
                    this.tiktok = this.social.tiktok
                    this.threads = this.social.threads
                }
            }
            this.loadingSocial = false
            this.toggleProgress("hide");
        },
        async GetDashboardExtra() {
            this.toggleProgress("show");
            this.loadingSocial = true
            let apiResponse = await ApiService.GetDashboardExtra(
                this.page,
                this.per_page
            );
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
                this.ad = apiResponse["ad"];

                this.viewPostAd()
            }
            this.loadingSocial = false
            this.toggleProgress("hide");
        },
        async viewPostAd() {
            let apiResponse = await ApiService.ViewPostAd(
                this.ad['id']
            );
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
            }
        },
        async clickPostAd() {
            let apiResponse = await ApiService.ClickPostAd(
                this.ad['id']
            );
            // console.log('UserFollowership',apiResponse)
            if (apiResponse["status"] == "success") {
            }
        },
        setTask(task) {
            if (task['platform'] == 'instagram') {
                this.currentTaskButton = 'accept'
            } else if (task['platform'] == 'tiktok') {
                this.currentTaskButton = 'accept'
            } else if (task['platform'] == 'facebook' && task['type'] == 'share') {
                this.currentTaskButton = 'accept'
            } else if (task['platform'] == 'youtube' && task['type'] == 'comment') {
                this.currentTaskButton = 'accept'
            } else if (task['platform'] == 'twitter' && task['type'] == 'comment') {
                this.currentTaskButton = 'accept'
            } else {
                this.currentTaskButton = 'perform'
            }
            this.current_task = task
        },
        setTaskFirstWarning(task) {
            let warning = ''
            if (this.first_warning && this.first_warning['value']) {
                warning = this.first_warning['value']
                if (warning) {
                    warning = this.replaceAll(warning, 'Instagram', task['platform'])
                }
            }
            return warning
        },
        performTask(url, key) {
            $(".c").hide("slow");
            this.timer = 70000;
            this.event = 0;
            this.urlChange = true;
            focus = false;
            console.log(url);
            console.log(key);
            let checkYT = url.search('youtube.com');
            if (checkYT > -1) {
                this.timer = 70000;
            }
            this.openWindow(url);
            let vue_this = this;
            let vue_win = window;
            setTimeout(function () {
                vue_this.checkWindow(url, key);
            }, vue_this.timer);
        },
        openWindow(url) {
            //nWind = window.open(url, ""); noopener noreferrer
            this.nWind = window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000px,height=500px");
            console.log(this.nWind);
            this.nWind.addEventListener('click', function () {
                this.event = this.event + 1;
                console.log(this.event);
                console.log('click');
            });
        },
        async checkWindow(url, key) {
            console.log('not closed');

            this.loadingTasks = true
            let apiResponse = await ApiService.PerformTask(this.current_task['id']);
            // console.log('GetMe',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(function () {
                    location.href = location.href;
                }, 2000);
            }
            this.loadingTasks = false

            this.closeWindow();
        },
        closeWindow() {
            console.log('closeWindow')
            // this.nWind.close();
        },
        async acceptTask(key) {
            $("button").prop('disabled', true)
            this.loadingTasks = true
            let apiResponse = await ApiService.AcceptTask(this.current_task['id']);
            // console.log('GetMe',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(function () {
                    location.href = apiResponse['url'];
                }, 2000);
            }
            this.loadingTasks = false
        },
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #FFF;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
